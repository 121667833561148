import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";
import moment from "moment";
import * as XLSX from "xlsx";

const PackageEnquiry = () => {
  const [show4, setShow4] = useState();
  const [Data, setData] = useState("");
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  //integrating get  method
  const [Package, setPackage] = useState([]);
  const getPackage = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/user/getpackage");
      if (res.status === 200) {
        setPackage(res.data.getpackage);
        setNoChangeData(res.data.getpackage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Delete
  const DeletePackage = async () => {
    try {
      const config = {
        url: "user/Deletepackage/" + Data,
        method: "delete",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getPackage();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  useEffect(() => {
    getPackage();
  }, []);

  // Pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = Package.slice(firstIndex, lastIndex);
  const npages = Math.ceil(Package.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }

  // Search filter
  const [nochangedata, setNoChangeData] = useState([]);
  const [searchH, setSearchH] = useState("");

  const handleFilterH = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchH(searchTerm);
    if (searchTerm !== "") {
      const filteredData = nochangedata.filter((user) =>
        Object.values(user).some((value) =>
          String(value).toLowerCase().includes(searchTerm)
        )
      );
      setPackage(filteredData);
    } else {
      setPackage(nochangedata);
    }
  };

  // ==============DATE FILTER======================//

  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");

  const filterData = () => {
    if (!startDate) return alert("Please select a 'from' date");
    if (!endDate) return alert("Please select a 'to' date");

    // Convert input dates to moment objects
    const startDateObj = moment(startDate, "YYYY-MM-DD");
    const endDateObj = moment(endDate, "YYYY-MM-DD");

    // Ensure the end date is not before the start date
    if (endDateObj.isBefore(startDateObj)) {
      return alert("End date cannot be before the start date");
    }

    // Filter Addregister array based on the date range
    const filteredData = Package.filter((item) => {
      const itemDate = moment(item?.createdAt, "YYYY-MM-DD");
      // Check if itemDate falls between startDate and endDate
      console.log(
        "itemDate..",
        itemDate === startDateObj,
        itemDate === endDateObj
      );

      return (
        itemDate.isValid() &&
        itemDate.isSameOrAfter(startDateObj) &&
        itemDate.isSameOrBefore(endDateObj)
      );
    });
    console.log("Filtered Data: ", filteredData);
    // Update the state with the filtered data
    if (filteredData.length > 0) {
      setPackage(filteredData);
    } else {
      alert("No records found within the selected date range");
      setPackage([]); // Optionally, clear the data if no records found
    }
  };

  function clearbutton() {
    setendDate("");
    setstartDate("");
    getPackage();
  }

  // Export Excel
  const handleExportExcel = () => {
    // Create a custom mapping for the column headers
    const customHeaders = Package.map((item) => ({
      "Date / Time": moment(item.createdAt).format("MM/DD/YYYY, hh:mm A"),
      "User Name": item.PUserName,
      "Phone Number": item.PUserPhone,
      "Email ID": item.PUserEmail,
      Message: item.PUserMessage,
    }));

    // Convert your custom data to an Excel sheet
    const worksheet = XLSX.utils.json_to_sheet(customHeaders);

    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Database Enquiries");

    // Download the Excel file
    XLSX.writeFile(workbook, "PackageEnquiry.xlsx");
  };

  return (
    <div>
      <div>
        <div className="d-flex gap-3 mb-2">
          <div className="col-lg-3 d-flex justify-content-center">
            <div class="input-group ">
              <span class="input-group-text" id="basic-addon1">
                <BsSearch />
              </span>
              <input
                type="text"
                class="form-control"
                placeholder="Search..."
                aria-describedby="basic-addon1"
                onChange={handleFilterH}
              />
            </div>
          </div>
          <div className="col-md-3 d-flex justify-content-center align-items-center">
            <div className="input-group">
              <label htmlFor="" className="m-auto">
                From: &nbsp;
              </label>
              <input
                type="date"
                className="form-control"
                aria-describedby="date-filter"
                value={startDate}
                onChange={(e) => setstartDate(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-3 d-flex justify-content-center align-items-center">
            <div className="input-group">
              <label htmlFor="" className="m-auto">
                To: &nbsp;
              </label>
              <input
                type="date"
                className="form-control"
                aria-describedby="date-filter"
                value={endDate}
                onChange={(e) => setendDate(e.target.value)}
              />
            </div>
          </div>
          <div>
            <Button onClick={filterData}>Submit</Button>
          </div>
          <div>
            <Button variant="danger mt-1" onClick={clearbutton}>
              Clear
            </Button>
          </div>
        </div>

        <div className="customerhead p-2">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="header-c ">Package Enquiry</h2>
            <Button variant="" className="success" onClick={handleExportExcel}>
              Export Excel
            </Button>
          </div>

          <div className="mb-3">
            <Table
              responsive
              bordered
              style={{ width: "-webkit-fill-available" }}
            >
              <thead>
                <tr>
                  <th>SL.NO</th>
                  <th>Enquiry Date</th>
                  <th>Name</th>
                  <th>Email ID</th>
                  <th>Phone Number</th>
                  <th>Message</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {records?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ paddingTop: "20px" }}>
                        {i + 1 + firstIndex}
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        {new Date(item.PEnquiryDate).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          }
                        )}
                      </td>
                      <td style={{ paddingTop: "20px" }}>{item.PUserName}</td>
                      <td style={{ paddingTop: "20px" }}>{item.PUserEmail}</td>
                      <td style={{ paddingTop: "20px" }}>{item.PUserPhone}</td>
                      <td style={{ paddingTop: "20px" }}>
                        {parse(`<div>${item.PUserMessage}</div>`)}
                      </td>

                      <td>
                        <div>
                          <AiFillDelete
                            className="text-danger"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow4();
                              setData(item?._id);
                            }}
                          />{" "}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <div>
              <nav>
                <ul className="pagination">
                  <li className="not-allow">
                    <span>
                      <li className="next-prev">
                        <a
                          onClick={() => {
                            prevpage();
                          }}
                        >
                          &lt;
                        </a>{" "}
                      </li>
                    </span>
                  </li>
                  {numbers?.map((n, i) => {
                    return (
                      <li className="active-next" key={i}>
                        <a
                          href="#"
                          className="inactive"
                          onClick={() => changePage(n)}
                        >
                          {n}
                        </a>
                      </li>
                    );
                  })}

                  <li className="not-allow">
                    <span>
                      <li
                        className="next-prev"
                        onClick={() => {
                          nextpage();
                        }}
                      >
                        &gt;{" "}
                      </li>
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        {/* Delete booking */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={DeletePackage}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default PackageEnquiry;
