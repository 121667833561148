import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import parse from "html-react-parser";

const AdminServices = () => {
  // Add modal
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Delete modal
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // integrating post method
  const formdata = new FormData();
  const [ServiceImage, setServiceImage] = useState("");
  const [ServiceName, setServiceName] = useState("");
  const [ServiceDescription, setServiceDescription] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setServiceDescription(data);
  };

  const Addservicedata = async () => {
    formdata.append("ServiceImage", ServiceImage);
    formdata.append("ServiceName", ServiceName);
    formdata.append("ServiceDescription", ServiceDescription);

    try {
      if (!ServiceImage) {
        return alert("Please add Images");
      }
      if (!ServiceName) {
        return alert("Please add Service Name");
      }
      if (!ServiceDescription) {
        return alert("Please add Service Description");
      }
      const config = {
        url: "admin/service",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddservice();
        handleClose();
        setServiceImage("");
        setServiceName("");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get method
  const [Addservice, setAddservice] = useState([]);
  const getAddservice = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getservice");
      if (res.status === 200) {
        setAddservice(res.data.getservice);
        setNoChangeData1(res.data.getservice);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const Deletservice = async () => {
    try {
      const config = {
        url: "admin/Deleteservice/" + Data,
        method: "delete",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddservice();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
    setServiceName(item?.ServiceName);
    setServiceDescription(item?.ServiceDescription);
  };

  const Editservice = async (e) => {
    e.preventDefault();
    formdata.append("ServiceImage", ServiceImage);
    formdata.append("ServiceName", ServiceName);
    formdata.append("ServiceDescription", ServiceDescription);
    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/editservice",
        method: "put",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose3();
          getAddservice();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getAddservice();
  }, []);

  // pagination
  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = Addservice.slice(firstIndex, lastIndex);
  const npages = Math.ceil(Addservice.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }

  // Search filter
  const [nochangedata1, setNoChangeData1] = useState([]);
  const [searchH1, setSearchH1] = useState("");

  const handleFilterH = (e) => {
    const searchTerm1 = e.target.value.toLowerCase();
    setSearchH1(searchTerm1);
    if (searchTerm1 !== "") {
      const filteredData1 = nochangedata1.filter((user) =>
        Object.values(user).some((value) =>
          String(value).toLowerCase().includes(searchTerm1)
        )
      );
      setAddservice(filteredData1);
    } else {
      setAddservice(nochangedata1);
    }
  };

  return (
    <div>
      <div>
        <div className="col-lg-4 d-flex justify-content-center">
          <div class="input-group ">
            <span class="input-group-text" id="basic-addon1">
              <BsSearch />
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              aria-describedby="basic-addon1"
              onChange={handleFilterH}
            />
          </div>
        </div>
        <div className="customerhead p-2">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="header-c ">Services</h2>
            <div className="d-flex gap-3">
              <button className="admin-add-btn" onClick={handleShow}>
                Add Services
              </button>
            </div>
          </div>

          <div className="mb-3">
            <Table
              responsive
              bordered
              style={{ width: "-webkit-fill-available" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th style={{ width: "150px" }}>Service Image</th>
                  <th style={{ width: "150px" }}>Service Name</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {records?.map((item, i) => {
                  return (
                    <tr>
                      <td style={{ paddingTop: "20px" }}>
                        {i + 1 + firstIndex}
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        <Image
                          src={`https://nestwayinteriors.com/Services/${item?.ServiceImage}`}
                          alt="pic"
                          style={{ width: "75px", height: "75px" }}
                        />{" "}
                      </td>
                      <td style={{ paddingTop: "20px" }}>{item.ServiceName}</td>
                      <td style={{ paddingTop: "20px" }}>
                        <div className="scroller">
                          {parse(`<div>${item.ServiceDescription}</div>`)}
                        </div>
                      </td>

                      <td style={{ paddingTop: "20px" }}>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <BiSolidEdit
                              className="text-success"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => handleShow3(item)}
                            />{" "}
                          </div>
                          <div>
                            <AiFillDelete
                              className="text-danger"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => {
                                handleShow4();
                                setData(item?._id);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>

            <div>
              <nav>
                <ul className="pagination">
                  <li className="not-allow">
                    <span>
                      <li className="next-prev">
                        <a
                          onClick={() => {
                            prevpage();
                          }}
                        >
                          &lt;
                        </a>{" "}
                      </li>
                    </span>
                  </li>
                  {numbers?.map((n, i) => {
                    return (
                      <li className="active-next" key={i}>
                        <a
                          href="#"
                          className="inactive"
                          onClick={() => changePage(n)}
                        >
                          {n}
                        </a>
                      </li>
                    );
                  })}

                  <li className="not-allow">
                    <span>
                      <li
                        className="next-prev"
                        onClick={() => {
                          nextpage();
                        }}
                      >
                        &gt;{" "}
                      </li>
                    </span>
                  </li>
                </ul>
              </nav>
            </div>
          </div>

          {/* Add Services modal  */}
          <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
            <Modal.Header closeButton>
              <Modal.Title>Add Services</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Service Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setServiceImage(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Service Name</label>
                  <input
                    type="text"
                    className="vi_0"
                    placeholder="Enter Service Name"
                    onChange={(e) => setServiceName(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Description</label>
                  <CKEditor editor={ClassicEditor} onChange={handleChange} />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <Button
                  className="mx-2 modal-close-btn"
                  variant=""
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  className="mx-2 modal-add-btn"
                  variant=""
                  onClick={Addservicedata}
                >
                  Add
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* Edit Home Slider modal  */}
          <Modal
            show={show3}
            onHide={handleClose3}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black" }}>
                Edit Services
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Services Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setServiceImage(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Service Name</label>
                  <input
                    type="text"
                    className="vi_0"
                    value={ServiceName}
                    onChange={(e) => setServiceName(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={ServiceDescription}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="modal-close-btn"
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                variant=""
                className="modal-add-btn"
                onClick={Editservice}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>

          {/*Delet Package modal for Slider */}
          <Modal
            show={show4}
            onHide={handleClose4}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <p className="fs-4" style={{ color: "red" }}>
                    Are you sure?
                    <br /> you want to delete this data?
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="modal-close-btn"
                onClick={handleClose4}
              >
                Close
              </Button>
              <Button
                variant=""
                className="modal-add-btn"
                onClick={Deletservice}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AdminServices;
