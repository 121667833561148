import React, { useState } from "react";
import axios from "axios";
import "../Admin/Admin.css";
import { IoEyeOffSharp } from "react-icons/io5";
import { FaEye } from "react-icons/fa";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";

function AdminLogin() {
  const [REmail, setREmail] = useState("");
  const [RPassword, setRPassword] = useState("");

  const adminLogin = async () => {
    try {
      const config = {
        url: "admin/adminLogin",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        headers: { "content-type": "application/json" },
        data: { REmail: REmail, RPassword: RPassword },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert("Successfully login");
        window.sessionStorage.setItem(
          "admin",
          JSON.stringify(res.data.success)
        );
        window.location.assign("/dashboard");
      }
    } catch (error) {
      alert("Please Enter Registered Email ID or Password");
      console.log(error);
    }
  };

  // passwrd view icon
  const [passwordVisible, setPasswordVisible] = useState(false); // State to toggle password visibility

  const [show8, setShow8] = useState(false);
  const handleClose8 = () => setShow8(false);
  const handleShow8 = () => setShow8(true);

  const [show9, setShow9] = useState(false);
  const handleClose9 = () => setShow9(false);
  const handleShow9 = () => {
    setEmailError(""); // Clear previous errors
    setemail("");
    setShow9(true);
  };

  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(IoEyeOffSharp);

  //otp
  const [email, setemail] = useState("");

  const [emailv, setemailv] = useState("");
  const [otp, setotp] = useState("");
  const [admindata, setadmindata] = useState({});

  const sendOTP = async () => {
    if (!email) {
      alert("Please enter your registered Email ID");
      return;
    } else if (!validateEmail(email)) {
      alert("Please enter a valid Email ID");
      return;
    }

    try {
      const config = {
        url: "/admin/sendOtp",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          email: email,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        setemailv(res.data.success);
        alert("OTP sent to your Email ID");
        handleClose9(); // Close the Reset Password modal
        handleShow8(); // Open the OTP verification modal only after a successful response
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //verify OTP

  const verifyOTP = async () => {
    try {
      if (!otp) {
        return alert("Enter a valid OTP");
      }
      const config = {
        url: "/admin/VerifyOtp",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          email: emailv,
          otp: otp,
        },
      };
      const res = await axios(config);
      if (res.status === 200) {
        setadmindata(res.data.success);
        handleClose8();
        handleShow7(); // Open the New Password modal only after OTP verification is successful
        alert("OTP verified successfully");
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //changepassword
  const [show7, setShow7] = useState(false);
  const handleClose7 = () => setShow7(false);
  const handleShow7 = () => setShow7(true);

  const [changepassword, setchangepassword] = useState("");

  const ChangePassword = async () => {
    try {
      if (!admindata) {
        return alert("Enter New Password");
      }
      const config = {
        url: "/admin/ChangePassword",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          AdminId: admindata?._id,
          RPassword: changepassword,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert(res.data.success);
          handleClose7();
          handleClose8();
          handleClose9();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const handleToggle = () => {
    if (type === "password") {
      setIcon(FaEye);
      setType("text");
    } else {
      setIcon(IoEyeOffSharp);
      setType("password");
    }
  };

  // Email condition
  const [emailError, setEmailError] = useState("");

  const validateEmail = (email) => {
    // Regular expression to validate email format
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setemail(inputEmail); // Updated to set the correct state

    // Check if the email is not empty and if it matches the pattern
    if (!inputEmail) {
      setEmailError("Email ID cannot be empty");
    } else if (!validateEmail(inputEmail)) {
      setEmailError("Please enter a valid Email ID");
    } else {
      setEmailError("");
    }
  };
  return (
    <>
      <div className="bg-img1">
        <div className="add">
          <div className="container">
            <div className="fw_90">
              <div className="add_0">
                <div className="im09">
                  <div className="d-flex">
                    <img
                      src="../Assets/nestwaylogo.jpeg"
                      alt="Logo"
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        height: "150px",
                      }}
                      className="admin-login-logo"
                    />
                  </div>
                </div>
                <div className="add-90">
                  <form>
                    <div className="sd_00 mb-2">
                      <label>Email</label> <br />
                      <input
                        type="email"
                        placeholder="Enter Your Email ID"
                        className="name_0"
                        value={REmail}
                        onChange={(e) => setREmail(e.target.value)}
                        required
                      />
                    </div>
                    <div className="sd_00 mb-2">
                      <label>Password</label>
                      <br />
                      <div style={{ position: "relative" }}>
                        <input
                          type={passwordVisible ? "text" : "password"} // Toggle input type
                          placeholder="Password"
                          className="name_0"
                          value={RPassword}
                          onChange={(e) => setRPassword(e.target.value)}
                          required
                          style={{ paddingRight: "40px" }}
                        />
                        <span
                          onClick={() => setPasswordVisible(!passwordVisible)} // Toggle visibility
                          style={{
                            position: "absolute",
                            right: "10px",
                            top: "50%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                        >
                          {passwordVisible ? "👁️" : "🙈"}
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleShow9();
                      }}
                    >
                      Forgot Password?
                    </div>
                    <div className="sd_00 mt-2">
                      {" "}
                      <button
                        type="button"
                        style={{ background: "white", color: "black" }}
                        onClick={() => adminLogin()}
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* RESET PASSWORD  */}
        <Modal style={{ zIndex: "9999999" }} show={show9} onHide={handleClose9}>
          <Modal.Header closeButton>
            <Modal.Title> Reset Password</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <label
                    htmlFor=""
                    style={{
                      color: "#1f321f",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Enter Registered Email ID
                  </label>
                  <InputGroup className=" mt-2 mb-3">
                    <Form.Control
                      type="mail"
                      className="vi_0"
                      aria-label="Default"
                      placeholder="Enter Registered Email ID"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={handleEmailChange}
                    />
                  </InputGroup>
                  {emailError && (
                    <div style={{ color: "red", fontSize: "14px" }}>
                      {emailError}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={sendOTP}>Send OTP</Button>
          </Modal.Footer>
        </Modal>

        {/* OTP VARIFICATION MODAL  */}
        <Modal style={{ zIndex: "9999999" }} show={show8} onHide={handleClose8}>
          <Modal.Header closeButton>
            <Modal.Title>OTP Verification</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <label
                    htmlFor=""
                    style={{
                      color: "#1f321f",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Enter OTP
                  </label>
                  <InputGroup className="mt-2 mb-3">
                    <Form.Control
                      aria-label="Default"
                      placeholder="Enter OTP"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(e) => setotp(e.target.value)}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={verifyOTP}>Verify OTP</Button>
          </Modal.Footer>
        </Modal>

        {/* SET NEW PASSWORD */}
        <Modal style={{ zIndex: "9999999" }} show={show7} onHide={handleClose7}>
          <Modal.Header closeButton>
            <Modal.Title>New Password</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <label
                    htmlFor=""
                    style={{
                      color: "#1f321f",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    Enter New Password
                  </label>
                  <InputGroup className="mt-2 mb-3">
                    <Form.Control
                      aria-label="Default"
                      placeholder="Enter New Password"
                      aria-describedby="inputGroup-sizing-default"
                      onChange={(e) => setchangepassword(e.target.value)}
                    />
                  </InputGroup>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={ChangePassword}>Submit</Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default AdminLogin;
