import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import { IoMdEye } from "react-icons/io";

const AdminGallery = () => {
  // ====================GALLERY IMAGE========================//

  // View modal
  const [View, setView] = useState({});
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);
  const handleShow6 = () => setShow6(true);

  // Add modal
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Delete modal
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // integrating post method
  const formdata = new FormData();
  const [GalleryImage, setGalleryImage] = useState([]);
  const [GalleryimgName, setGalleryimgName] = useState("");
  const [GalleryCategory, setGalleryCategory] = useState("");

  const AddGalleryimg = async () => {
    formdata.append("GalleryImage", GalleryImage);
    formdata.append("GalleryimgName", GalleryimgName);
    formdata.append("GalleryCategory", GalleryCategory);

    try {
      if (!GalleryImage) {
        return alert("Please add Images");
      }
      if (!GalleryCategory) {
        return alert("Please select gallery category");
      }
      const config = {
        url: "admin/galleryimg",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddgalleryimg();
        handleClose3();
        setGalleryimgName("");
        setGalleryCategory("");
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get method
  const [Addgalleryimg, setAddgalleryimg] = useState([]);
  const getAddgalleryimg = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getgalleryimg"
      );
      if (res.status === 200) {
        setAddgalleryimg(res.data.getgalleryimg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Datas, setDatas] = useState("");
  const Deletgallery = async () => {
    try {
      const config = {
        url: "admin/Deletegalleryimg/" + Datas,
        method: "delete",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddgalleryimg();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data2, setData2] = useState("");
  const [show5, setShow5] = useState(false);
  const handleClose5 = () => setShow5(false);
  const handleShow5 = (item) => {
    setShow5(true);
    setData2(item);
    setGalleryimgName(item?.GalleryimgName);
    setGalleryCategory(item?.GalleryCategory);
  };

  const Editgallery = async (e) => {
    e.preventDefault();
    formdata.append("GalleryImage", GalleryImage);
    formdata.append("GalleryimgName", GalleryimgName);
    formdata.append("GalleryCategory", GalleryCategory);
    formdata.append("id", Data2?._id);
    try {
      const config = {
        url: "admin/editgalleryimg",
        method: "put",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose5();
          getAddgalleryimg();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getAddgalleryimg();
  }, []);

  //integrating get method
  const [Addservice, setAddservice] = useState([]);
  const getAddservice = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getservice");
      if (res.status === 200) {
        setAddservice(res.data.getservice);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddservice();
  }, []);

  return (
    <div>
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Gallery Images</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Gallery Images
            </button>
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Gallery Category Name</th>
                <th>Gallery Images</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {Addgalleryimg?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td style={{ paddingTop: "20px" }}>{i + 1}</td>
                    <td style={{ paddingTop: "20px" }}>
                      {item.GalleryCategory}
                    </td>
                    <td style={{ paddingTop: "20px" }}>
                      <IoMdEye
                        style={{ fontSize: "22" }}
                        onClick={() => {
                          handleShow6();
                          setView(item);
                        }}
                      />
                    </td>
                    <td style={{ paddingTop: "20px" }}>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className="text-success"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => handleShow5(item)}
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className="text-danger"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow4();
                              setDatas(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Galleryimg modal  */}
        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Gallery Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Select Gallery Category</label>
                <select
                  style={{ padding: "8px", borderRadius: "5px" }}
                  className="form-control"
                  onChange={(e) => setGalleryCategory(e.target.value)}
                >
                  <option value="">Select Category</option>
                  {Addservice?.map((item, i) => {
                    return (
                      <option value={item.ServiceName}>
                        {item.ServiceName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Images</label>
                <input
                  type="file"
                  className="vi_0"
                  onChange={(e) => {
                    setGalleryImage(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Gallery Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Gallery Title"
                  onChange={(e) => setGalleryimgName(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={() => AddGalleryimg()}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Galleryimg modal  */}
        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit Gallery Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Select Gallery Category</label>
                <select
                  style={{ padding: "8px", borderRadius: "5px" }}
                  className="form-control"
                  onChange={(e) => setGalleryCategory(e.target.value)}
                >
                  <option value="">Select Category</option>
                  {Addservice?.map((item, i) => {
                    return (
                      <option value={item.ServiceName}>
                        {item.ServiceName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Gallery Images</label>
                <input
                  type="file"
                  className="vi_0"
                  onChange={(e) => {
                    setGalleryImage(e.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Gallery Title</label>
                <input
                  type="text"
                  className="vi_0"
                  value={GalleryimgName}
                  onChange={(e) => setGalleryimgName(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn" onClick={Editgallery}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delet Galleryimg modal  */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn" onClick={Deletgallery}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Gallery Image View Modal  */}
        <Modal
          show={show6}
          onHide={handleClose6}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Gallery Images</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-center">
              <div className="col-md-6 mb-2">
                <div>
                  <Image
                    src={`https://nestwayinteriors.com/Gallery/${View?.GalleryImage}`}
                    alt="pic"
                    style={{ width: "100%", heigh: "300px" }}
                  />
                </div>
                <div className="mt-2 mb-2">{View.GalleryimgName}</div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose6}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminGallery;
