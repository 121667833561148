import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
  MdSubject,
} from "react-icons/md";
import "../Admin/Admin.css";
import Navbar from "react-bootstrap/Navbar";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { AiOutlineHome } from "react-icons/ai";
import { TfiLayoutSliderAlt } from "react-icons/tfi";
import { RiTeamFill } from "react-icons/ri";
import { GrProjects } from "react-icons/gr";
import { FaClipboardQuestion } from "react-icons/fa6";
import { FaUpDownLeftRight } from "react-icons/fa6";
import { RiDashboardFill } from "react-icons/ri";
import { FaNetworkWired } from "react-icons/fa6";
import { PiGlobeStandFill } from "react-icons/pi";
import { SiShanghaimetro } from "react-icons/si";
import { GrBusinessService } from "react-icons/gr";
import { GrGallery } from "react-icons/gr";
import { LuAlignVerticalDistributeEnd } from "react-icons/lu";
import { MdOutlineSupportAgent } from "react-icons/md";
import { MdDashboardCustomize } from "react-icons/md";
import { IoBookmarksSharp } from "react-icons/io5";
import { TbPackages } from "react-icons/tb";

const Side = () => {
  const [acc, setacc] = useState(true);
  const [acc1, setacc1] = useState(false);
  const [acc2, setacc2] = useState(false);
  const [acc3, setacc3] = useState(false);
  const [acc4, setacc4] = useState(false);
  const [acc5, setacc5] = useState(false);
  const [acc6, setacc6] = useState(false);
  const [acc7, setacc7] = useState(false);
  const [acc8, setacc8] = useState(false);

  const [Home, setHome] = useState(false);
  const [About, setAbout] = useState(false);

  // Responsive sidebar
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  //integrating get  method
  const [Bookings, setBookings] = useState([]);
  const getBookings = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/user/getbooking");
      if (res.status === 200) {
        setBookings(res.data.getbooking);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBookings();
  }, []);

  //integrating get  method
  const [Addgeneral, setAddgeneral] = useState([]);
  const getgeneral = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/user/getgeneral");
      if (res.status === 200) {
        setAddgeneral(res.data.getgeneral);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getgeneral();
  }, []);

  //integrating get  method of Package enquiry
  const [Package, setPackage] = useState([]);
  const getPackage = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/user/getpackage");
      if (res.status === 200) {
        setPackage(res.data.getpackage);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPackage();
  }, []);

  return (
    <div>
      <Navbar expand="lg" className=" p-0" style={{ height: "100%" }}>
        <button
          class="custom-toggler navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarsExample09"
          aria-controls="navbarsExample09"
          aria-expanded={!isNavCollapsed ? true : false}
          aria-label="Toggle navigation"
          onClick={handleNavCollapse}
          style={{ margin: "10px" }}
        >
          <span>
            <GiHamburgerMenu style={{ color: "white" }} />
          </span>
        </button>
        <div
          class={`${isNavCollapsed ? "collapse" : ""} navbar-collapse`}
          id="navbarsExample09"
        >
          <div className="si09">
            <div style={{ width: "100%", justifyContent: "space-between" }}>
              <div
                className="lo-ad"
                style={{ background: "white", borderBottom: "1px solid white" }}
              >
                <div className="">
                  <img
                    src="../Assets/nestwaylogo.jpeg"
                    alt="Logo"
                    className="admin-logo-img"
                    style={{ width: "260px" }}
                  />
                </div>
              </div>
              <div className="sidebar-close-icon" onClick={handleNavCollapse}>
                <AiOutlineClose />
              </div>
            </div>
            <ul>
              <Link to="/dashboard" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <MdDashboardCustomize style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Dashboard</span>
                </li>
              </Link>

              <Link to="/admin_booking_list" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <IoBookmarksSharp style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">
                    Booking List ( {Bookings?.length} )
                  </span>
                </li>
              </Link>

              <Link to="/package_enquiry" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <TbPackages style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">
                    Package Enquiry( {Package?.length} )
                  </span>
                </li>
              </Link>

              <Link to="/admin_general_enquiry" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <MdOutlineSupportAgent style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">
                    General Enquiry( {Addgeneral?.length} )
                  </span>
                </li>
              </Link>

              <Link to="/admin_services" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <GrBusinessService style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Services </span>
                </li>
              </Link>

              {/* Home Dropdowns  */}
              <Link to="">
                <li
                  className={`a-ele ${acc3 ? "active-0" : "null"}`}
                  onClick={() => {
                    setHome(!Home);
                  }}
                >
                  <span>
                    <AiOutlineHome style={{ fontSize: "20px" }} />
                  </span>{" "}
                  <span>Home </span>{" "}
                  {Home ? (
                    <>
                      {" "}
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowUp />
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    </>
                  )}
                </li>
              </Link>
              <Link to="">
                {Home ? (
                  <>
                    <div className="webmanagement">
                      <Link to="/home_slider">
                        <li
                          className={`a-ele ${acc ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(true);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                          }}
                        >
                          <span>
                            <TfiLayoutSliderAlt style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">Project</span>
                        </li>
                      </Link>
                      <Link to="/home_how_we_work">
                        <li
                          className={`a-ele ${acc1 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(true);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                          }}
                        >
                          <span>
                            {" "}
                            <RiTeamFill style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">How We Work </span>{" "}
                        </li>
                      </Link>
                      <Link to="/home_our_projects">
                        <li
                          className={`a-ele ${acc2 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(true);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                          }}
                        >
                          <span>
                            {" "}
                            <GrProjects style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">Our Projects</span>{" "}
                        </li>
                      </Link>
                      <Link to="/home_why_choose_us">
                        <li
                          className={`a-ele ${acc3 ? "active2" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(true);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                          }}
                        >
                          <span>
                            {" "}
                            <FaClipboardQuestion style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">Why Choose Us </span>{" "}
                        </li>
                      </Link>

                      <Link to="/home_quality-of-work">
                        <li
                          className={`a-ele ${acc4 ? "active4" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(true);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                          }}
                        >
                          <span>
                            {" "}
                            <FaUpDownLeftRight style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">Quality of Work</span>{" "}
                        </li>
                      </Link>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Link>

              {/* About us Dropdowns  */}
              <Link to="">
                <li
                  className={`a-ele ${acc5 ? "active-0" : "null"}`}
                  onClick={() => {
                    setAbout(!About);
                  }}
                >
                  <span>
                    <RiDashboardFill style={{ fontSize: "20px" }} />
                  </span>{" "}
                  <span>Web Management </span>{" "}
                  {About ? (
                    <>
                      {" "}
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowUp />
                      </span>
                    </>
                  ) : (
                    <>
                      <span style={{ float: "right" }}>
                        <MdOutlineKeyboardArrowDown />
                      </span>
                    </>
                  )}
                </li>
              </Link>
              <Link to="">
                {About ? (
                  <>
                    <div className="webmanagement">
                      <Link to="/about_us">
                        <li
                          className={`a-ele ${acc5 ? "active3" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(true);
                            setacc6(false);
                            setacc7(false);
                            setacc8(false);
                          }}
                        >
                          <span>
                            <FaNetworkWired style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">About us</span>
                        </li>
                      </Link>
                      <Link to="/best_of_us">
                        <li
                          className={`a-ele ${acc6 ? "active3" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(true);
                            setacc7(false);
                            setacc8(false);
                          }}
                        >
                          <span>
                            {" "}
                            <PiGlobeStandFill style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">Best of Us </span>{" "}
                        </li>
                      </Link>
                      <Link to="/vision_mission">
                        <li
                          className={`a-ele ${acc7 ? "active3" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(true);
                            setacc8(false);
                          }}
                        >
                          <span>
                            {" "}
                            <SiShanghaimetro style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">Vision & Mission</span>{" "}
                        </li>
                      </Link>
                      <Link to="/what_we_offer">
                        <li
                          className={`a-ele ${acc8 ? "active3" : "null"}`}
                          onClick={() => {
                            setacc(false);
                            setacc1(false);
                            setacc2(false);
                            setacc3(false);
                            setacc4(false);
                            setacc5(false);
                            setacc6(false);
                            setacc7(false);
                            setacc8(true);
                          }}
                        >
                          <span>
                            {" "}
                            <FaClipboardQuestion style={{ fontSize: "20px" }} />
                          </span>
                          <span className="ms-2">What We Offer </span>{" "}
                        </li>
                      </Link>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </Link>

              <Link to="/admin_gallery" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <GrGallery style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Gallery </span>
                </li>
              </Link>

              <Link to="/testimonial" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <GrGallery style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Testimonial </span>
                </li>
              </Link>

              <Link to="/admin_common_footer" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <LuAlignVerticalDistributeEnd
                      style={{ fontSize: "20px" }}
                    />
                  </span>
                  <span className="ms-2">Common Footer </span>
                </li>
              </Link>

              <Link to="/admin_contactus" onClick={handleNavCollapse}>
                <li className="a-ele ">
                  <span>
                    <MdSubject style={{ fontSize: "20px" }} />
                  </span>
                  <span className="ms-2">Contact Us</span>
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </Navbar>
    </div>
  );
};

export default Side;
