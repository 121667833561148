import React, { useState, useEffect } from "react";
import "../Styles/gallery.css";
import { Container } from "react-bootstrap";
import {
  FaArrowCircleUp,
  FaAngleRight,
  FaArrowCircleDown,
} from "react-icons/fa";
import Aos from "aos";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

const Gallery = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const categoryParam = query.get("category") || "ALL"; // Get the category from URL params

  const handleClick = () => {
    navigate("/");
  };

  // Scrool window icon

  const [visible, setVisible] = useState(false);
  const [atBottom, setAtBottom] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    const maxScroll =
      document.documentElement.scrollHeight - window.innerHeight;
    if (scrolled > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    if (scrolled >= maxScroll) {
      setAtBottom(true);
    } else {
      setAtBottom(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  const [Addcategory, setAddcategory] = useState([]);
  const [Addgalleryimg, setAddgalleryimg] = useState([]);
  const [filteredImages, setFilteredImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(categoryParam);

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoryRes = await axios.get(
          "https://nestwayinteriors.com/api/admin/getcategory"
        );
        const galleryRes = await axios.get(
          "https://nestwayinteriors.com/api/admin/getgalleryimg"
        );

        if (categoryRes.status === 200) {
          setAddcategory(categoryRes.data.getcategory);
        }

        if (galleryRes.status === 200) {
          setAddgalleryimg(galleryRes.data.getgalleryimg);
          setFilteredImages(galleryRes.data.getgalleryimg);  <div className="0"></div>
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedCategory === "ALL") {
      setFilteredImages(Addgalleryimg);
    } else {
      setFilteredImages(
        Addgalleryimg.filter((ele) => ele.GalleryCategory === selectedCategory)
      );
    }
  }, [selectedCategory, Addgalleryimg]);

  const [Addservice, setAddservice] = useState([]);
  const getAddservice = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getservice");
      if (res.status === 200) {
        setAddservice(res.data.getservice);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddservice();
  }, []);

  const uniqueCategories = [
    ...new Set(Addgalleryimg.map((item) => item?.GalleryCategory)),
  ];

  return (
    <div>
      <div className="gallerybackground-image-container">
        <div className="overlay">
          <div>
            <h2 className="servicebg-title" style={{ color: "#bd9d65" }}>
              GALLERY
            </h2>
            <br />
            <div className="nav-path-aboutus">
              <div onClick={handleClick}>Home / </div>&nbsp;
              <div style={{ color: "#bd9d65" }}> Gallery</div>
            </div>
          </div>

          <div
            className="fixed-icon"
            style={{ border: "none", backgroundColor: "unset !important" }}
          >
            <div>
              <a
                href="//"
                target="_new"
                style={{ color: "unset", textDecoration: "none" }}
              >
                <img
                  src="../Assets/WhatsApp_icon.png"
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
                <span className="tooltip-text">Let's chat</span>
              </a>
            </div>
          </div>

          <div>
            <div
              className="scrool-up-icon"
              style={{ display: visible ? "inline" : "none" }}
            >
              <div className="scrool mb-2" onClick={scrollToTop}>
                <FaArrowCircleUp
                  style={{ fontSize: "40px", color: "#106b87" }}
                />
              </div>

              <div className="scrool" onClick={scrollToBottom}>
                <FaArrowCircleDown
                  style={{ fontSize: "40px", color: "#106b87" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container fluid>
        <div className="row mt-3 mb-3">
          <div
            className="col-md-3 mb-2"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="3000"
          >
            <div className="category">
              {Addgalleryimg?.length === 0 ? (
                ""
              ) : (
                <div className="tab" onClick={() => setSelectedCategory("ALL")}>
                  <FaAngleRight style={{ marginRight: "8px" }} />
                  All
                </div>
              )}

              {uniqueCategories.map((category, i) => (
                <div
                  key={i}
                  className="tab"
                  onClick={() => setSelectedCategory(category)}
                >
                  <FaAngleRight style={{ marginRight: "8px" }} />
                  {category}
                </div>
              ))}
            </div>
          </div>
          <div
            className="col-md-9 mb-2"
            data-aos="zoom-in"
            data-aos-delay="50"
            data-aos-duration="3000"
          >
            {filteredImages.length > 0 ? (
              <div className="row">
                {filteredImages.map((ele, index) => (
                  <div key={index} className="col-md-4 mb-2">
                    <div>
                      <img
                        src={`https://nestwayinteriors.com/Gallery/${ele?.GalleryImage}`}
                        alt=""
                        className="gallery-img"
                      />
                      <p>{ele?.GalleryimgName}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No images available in Gallery.</p>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Gallery;
