import React, { useState, useEffect } from "react";
import { PiArrowUp } from "react-icons/pi";
import "../Styles/about.css";
import { Container, Button } from "react-bootstrap";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { FaBuilding } from "react-icons/fa6";
import { FaUserPlus } from "react-icons/fa";
import { FaCheck } from "react-icons/fa";
import axios from "axios";
import parse from "html-react-parser";
import Aos from "aos";

const Aboutus = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };

  useEffect(() => {
    Aos.init();
  });

  // Scrool window icon

  const [visible, setVisible] = useState(false);
  const [atBottom, setAtBottom] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    const maxScroll =
      document.documentElement.scrollHeight - window.innerHeight;
    if (scrolled > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    if (scrolled >= maxScroll) {
      setAtBottom(true);
    } else {
      setAtBottom(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  //======================ABOUT US============================//
  //integrating get method
  const [Aboutus, setAboutus] = useState([]);
  const getAboutus = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getaboutus");
      if (res.status === 200) {
        setAboutus(res.data.getaboutus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAboutus();
  }, []);

  //======================BEST OF US============================//
  //integrating get method
  const [Bestus, setBestus] = useState([]);
  const getBestus = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getbestus");
      if (res.status === 200) {
        setBestus(res.data.getbestus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBestus();
  }, []);

  //======================VISSION AND MISSION============================//
  //integrating get method
  const [Addvisionmission, setAddvisionmission] = useState([]);
  const getAddvisionmission = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getvisionmission"
      );
      if (res.status === 200) {
        setAddvisionmission(res.data.getvisionmission);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddvisionmission();
  }, []);

  //======================WHAT WE OFFER============================//
  //integrating get method
  const [Whatweofr, setWhatweofr] = useState([]);
  const getWhatweofr = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getwhatweofr");
      if (res.status === 200) {
        setWhatweofr(res.data.getwhatweofr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getWhatweofr();
  }, []);

  return (
    <div>
      {/* Common Banner  */}
      <div className="about-background-image-container">
        <div className="overlay">
          <div>
            <h2 className="servicebg-title" style={{ color: "#bd9d65" }}>
              ABOUT US
            </h2>
            <br />
            <div className="nav-path-aboutus">
              <div onClick={handleClick}>Home / </div>&nbsp;
              <div style={{ color: "#bd9d65" }}> About us</div>
            </div>
          </div>

          <div
            className="fixed-icon"
            style={{ border: "none", backgroundColor: "unset !important" }}
          >
            <div>
              <a
                href="//"
                target="_new"
                style={{ color: "unset", textDecoration: "none" }}
              >
                <img
                  src="../Assets/WhatsApp_icon.png"
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
                <span className="tooltip-text">Let's chat</span>
              </a>
            </div>
          </div>

          <div>
            <div
              className="scrool-up-icon"
              style={{ display: visible ? "inline" : "none" }}
            >
              <div className="scrool mb-2" onClick={scrollToTop}>
                <FaArrowCircleUp
                  style={{ fontSize: "40px", color: "#106b87" }}
                />
              </div>

              <div className="scrool" onClick={scrollToBottom}>
                <FaArrowCircleDown
                  style={{ fontSize: "40px", color: "#106b87" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Container fluid>
          {Aboutus?.map((item, i) => {
            return (
              <div class="row mt-5 mb-2">
                <div
                  class="col-md-7 mb-2"
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="3000"
                >
                  <div>
                    <div class="about_us1">
                      <img
                        src={`https://nestwayinteriors.com/WebManagement/${item?.AboutusImage}`}
                        alt="abc"
                        className="about-img"
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-5 mb-2"
                  data-aos="fade-in"
                  data-aos-delay="50"
                  data-aos-duration="3000"
                >
                  <div
                    className="about-content"
                    data-aos="zoom-in"
                    data-aos-delay="50"
                    data-aos-duration="3000"
                  >
                    <div className="about_us-title">
                      <h6>ABOUT US</h6>
                    </div>

                    <div className="about_us-title mt-3">
                      <h3>{item.AboutusHeading}</h3>
                    </div>
                    <div>
                      <p className="about_us-title mt-3">
                        {parse(`<div>${item.AboutusDescription}</div>`)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Container>
      </div>

      <Container>
        <div
          className="row justify-content-center mt-5 mb-4"
          data-aos="zoom-in"
          data-aos-delay="50"
          data-aos-duration="3000"
        >
          {Bestus.map((item, i) => {
            return (
              <div className="col-md-4 mb-2">
                <div className="about-us-crd">
                  <div className="row">
                    <div className="col-md-6" image-container>
                      <img
                        src={`https://nestwayinteriors.com/WebManagement/${item?.BestusImage}`}
                        alt="pic"
                        style={{ width: "100%" }}
                      />
                    </div>
                    <div className="col-md-6 mb-2">
                      <div>
                        <div className="about-crd-title">
                          <h5>{item.BestusHeading}</h5>
                        </div>
                        <div className="about-crd-desc">
                          <p>{parse(`<div>${item.BestusDescription}</div>`)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {Addvisionmission.map((item, i) => {
          return (
            <div className="row mt-3 mb-3">
              <div
                className="col-md-6 mb-2"
                data-aos="zoom-in"
                data-aos-delay="50"
                data-aos-duration="3000"
              >
                <div className="vison-tiltle">
                  <h3 style={{ color: "#bd9d65" }}>Our Vision</h3>
                </div>
                <div className="vison-desc">
                  <p>{parse(`<div>${item.VisionDescription}</div>`)}</p>
                </div>

                <div className="vison-tiltle">
                  <h3 style={{ color: "#bd9d65" }}>Our Mission</h3>
                </div>
                <div className="vison-desc">
                  <p>{parse(`<div>${item.MissionDescription}</div>`)}</p>
                </div>
              </div>

              <div className="col-md-6 mb-2">
                <div
                  data-aos="zoom-in"
                  data-aos-delay="50"
                  data-aos-duration="3000"
                >
                  <img
                    src={`https://nestwayinteriors.com/WebManagement/${item?.VisionmissionImage}`}
                    alt=""
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </Container>

      <div className="work_1">
        <Container>
          {Whatweofr.map((item, i) => {
            return (
              <div className="wht-we-offr">
                <div className="row">
                  <div
                    className="col-md-7 mb-2"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="3000"
                  >
                    <div className="offer_l">
                      <div className="quality-of-wrk-title">
                        <h5 style={{ color: "#bd9d65" }}>WHAT WE OFFER?</h5>
                      </div>
                      <h1 className="mt-3 text-white">
                        {item.WhatweofrHeading}
                      </h1>
                      <div className="offer_li row mt-3">
                        <div className="col-md-6 mb-2">
                          <div className="offer_lil">
                            <ul className="mb-0 whtofr">
                              <li className="text-light">
                                <FaCheck
                                  className="col_yell"
                                  style={{ fontSize: "20px" }}
                                />{" "}
                                &nbsp;{" "}
                                <span style={{ fontSize: "20px" }}>
                                  {" "}
                                  {item.WhatweofrText1}
                                </span>
                              </li>
                              <li className="text-light">
                                <FaCheck
                                  className="col_yell"
                                  style={{ fontSize: "20px" }}
                                />{" "}
                                &nbsp;{" "}
                                <span style={{ fontSize: "20px" }}>
                                  {item.WhatweofrText2}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-md-6 mb-2">
                          <div className="offer_lil">
                            <ul className="mb-2 whtofr">
                              <li className="text-light">
                                <FaCheck
                                  className="col_yell"
                                  style={{ fontSize: "20px" }}
                                />{" "}
                                &nbsp;{" "}
                                <span style={{ fontSize: "20px" }}>
                                  {" "}
                                  {item.WhatweofrText3}
                                </span>
                              </li>

                              <li className="text-light">
                                <FaCheck
                                  className="col_yell"
                                  style={{ fontSize: "20px" }}
                                />{" "}
                                &nbsp;{" "}
                                <span style={{ fontSize: "20px" }}>
                                  {" "}
                                  {item.WhatweofrText4}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <p className="mt-3 what-we-offr-desc">
                        {parse(`<div>${item.WhatweofrDescription}</div>`)}
                      </p>
                    </div>
                  </div>
                  <div
                    className="col-md-5 mb-2"
                    data-aos="zoom-in"
                    data-aos-delay="50"
                    data-aos-duration="3000"
                  >
                    <div className="offer_r">
                      <div className="grid clearfix">
                        <figure className="effect-jazz mb-0">
                          <img
                            src={`https://nestwayinteriors.com/WebManagement/${item?.WhatweofrImage}`}
                            className="w-100 zoom-image"
                            alt="abc"
                          />
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Container>
      </div>
    </div>
  );
};

export default Aboutus;
