import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import parse from "html-react-parser";

const AdminBestofus = () => {
  // Add modal
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Delete modal
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // integrating post method
  const formdata = new FormData();
  const [BestusImage, setBestusImage] = useState("");
  const [BestusHeading, setBestusHeading] = useState("");
  const [BestusDescription, setBestusDescription] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setBestusDescription(data);
  };

  const Addbetsus = async () => {
    formdata.append("BestusImage", BestusImage);
    formdata.append("BestusHeading", BestusHeading);
    formdata.append("BestusDescription", BestusDescription);

    try {
      if (!BestusImage) {
        return alert("Please add Images");
      }
      if (!BestusHeading) {
        return alert("Please add Heading");
      }
      if (!BestusDescription) {
        return alert("Please add Description");
      }
      const config = {
        url: "admin/bestus",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getBestus();
        handleClose();
        setBestusImage("");
        setBestusHeading("");
        setBestusDescription("");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get method
  const [Bestus, setBestus] = useState([]);
  const getBestus = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getbestus");
      if (res.status === 200) {
        setBestus(res.data.getbestus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const Deletebestus = async () => {
    try {
      const config = {
        url: "admin/Deletebestus/" + Data,
        method: "delete",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getBestus();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
    setBestusHeading(item?.BestusHeading);
    setBestusDescription(item?.BestusDescription);
  };

  const Editbestus = async (e) => {
    e.preventDefault();
    formdata.append("BestusImage", BestusImage);
    formdata.append("BestusHeading", BestusHeading);
    formdata.append("BestusDescription", BestusDescription);
    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/editbestus",
        method: "put",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose3();
          getBestus();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getBestus();
  }, []);

  return (
    <div>
      <div>
        <div className="customerhead p-2">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="header-c ">Best of us</h2>
            <div>
              {Bestus?.length === 3 ? (
                ""
              ) : (
                <>
                  <button className="admin-add-btn" onClick={handleShow}>
                    Add Best of us
                  </button>
                </>
              )}
            </div>
          </div>

          <div className="mb-3">
            <Table
              responsive
              bordered
              style={{ width: "-webkit-fill-available" }}
            >
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Heading</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Bestus?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ paddingTop: "20px" }}>
                        <Image
                          src={`https://nestwayinteriors.com/WebManagement/${item?.BestusImage}`}
                          alt="pic"
                          style={{ width: "75px", height: "75px" }}
                        />
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        {item.BestusHeading}
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        <div className="scroller">
                          {parse(`<div>${item.BestusDescription}</div>`)}
                        </div>
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <BiSolidEdit
                              className="text-success"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => handleShow3(item)}
                            />{" "}
                          </div>
                          <div>
                            <AiFillDelete
                              className="text-danger"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => {
                                handleShow4();
                                setData(item?._id);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Add About us modal */}
          <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black" }}>
                Add Best of us
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setBestusImage(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Heading</label>
                  <input
                    type="text"
                    className="vi_0"
                    placeholder="Enter Heading"
                    onChange={(e) => setBestusHeading(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Description</label>
                  <CKEditor editor={ClassicEditor} onChange={handleChange} />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <Button
                  className="mx-2 modal-close-btn"
                  variant=""
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  className="mx-2 modal-add-btn"
                  variant=""
                  onClick={Addbetsus}
                >
                  Add
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* Edit About us modal */}
          <Modal
            show={show3}
            onHide={handleClose3}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black" }}>
                Edit Best of us
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setBestusImage(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Heading</label>
                  <input
                    type="text"
                    className="vi_0"
                    value={BestusHeading}
                    onChange={(e) => setBestusHeading(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={BestusDescription}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="modal-close-btn"
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button variant="" className="modal-add-btn" onClick={Editbestus}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Delet modal  */}
          <Modal
            show={show4}
            onHide={handleClose4}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <p className="fs-4" style={{ color: "red" }}>
                    Are you sure?
                    <br /> you want to delete this data?
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="modal-close-btn"
                onClick={handleClose4}
              >
                Close
              </Button>
              <Button
                variant=""
                className="modal-add-btn"
                onClick={Deletebestus}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AdminBestofus;
