import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";

const AdminOurProjects = () => {
  // Add modal
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //  Edit modal
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
    setOurprojectHeading(item?.OurprojectHeading);
    setOurprojectTagline(item?.OurprojectTagline);
  };

  // Delet modal for Social media
  const [show2, setShow2] = useState();
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // integrating post method
  const [OurprojectHeading, setOurprojectHeading] = useState("");
  const [OurprojectTagline, setOurprojectTagline] = useState("");

  const AddOurprojectsdetails = async () => {
    try {
      if (!OurprojectHeading) {
        return alert("Please add Heading");
      }
      if (!OurprojectTagline) {
        return alert("Please add Description");
      }
      const config = {
        url: "admin/ourproject",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          OurprojectHeading: OurprojectHeading,
          OurprojectTagline: OurprojectTagline,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getOurproject();
        handleClose();
        setOurprojectHeading("");
        setOurprojectTagline("");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get method
  const [Ourproject, setOurproject] = useState([]);
  const getOurproject = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getourproject"
      );
      if (res.status === 200) {
        setOurproject(res.data.getourproject);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const Deletproject = async () => {
    try {
      const config = {
        url: "admin/Deleteourproject/" + Data,
        method: "delete",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getOurproject();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const Editprojects = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "admin/editourproject",
        method: "put",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          OurprojectHeading: OurprojectHeading,
          OurprojectTagline: OurprojectTagline,
          id: Data1?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getOurproject();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getOurproject();
  }, []);

  return (
    <div>
      <div>
        <div className="customerhead p-2">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="header-c ">Our Projects</h2>
            <div className="d-flex gap-3">
              <div>
                {Ourproject?.length !== 0 ? (
                  ""
                ) : (
                  <>
                    <button className="admin-add-btn" onClick={handleShow}>
                      Add Our Projects
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="mb-3">
            <Table
              responsive
              bordered
              style={{ width: "-webkit-fill-available" }}
            >
              <thead>
                <tr>
                  <th>Heading</th>
                  <th>Tagline</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Ourproject?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ paddingTop: "20px" }}>
                        {item.OurprojectHeading}
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        {item.OurprojectTagline}
                      </td>
                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <BiSolidEdit
                              className="text-success"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => handleShow1(item)}
                            />{" "}
                          </div>
                          <div>
                            <AiFillDelete
                              className="text-danger"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => {
                                handleShow2();
                                setData(item?._id);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Add projects modal  */}
          <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
            <Modal.Header closeButton>
              <Modal.Title>Add Our Projects</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Heading</label>
                  <input
                    type="text"
                    className="vi_0"
                    placeholder="Enter Heading"
                    onChange={(e) => setOurprojectHeading(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Tagline</label>
                  <input
                    type="text"
                    className="vi_0"
                    placeholder="Enter Tagline"
                    onChange={(e) => setOurprojectTagline(e.target.value)}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <Button
                  className="mx-2 modal-close-btn"
                  variant=""
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  className="mx-2 modal-add-btn"
                  variant=""
                  onClick={AddOurprojectsdetails}
                >
                  Add
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* Edit Our Projects modal */}
          <Modal
            show={show1}
            onHide={handleClose1}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black" }}>
                Edit Our Projects
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Heading</label>
                  <input
                    type="text"
                    className="vi_0"
                    value={OurprojectHeading}
                    onChange={(e) => setOurprojectHeading(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Tagline</label>
                  <input
                    type="text"
                    className="vi_0"
                    value={OurprojectTagline}
                    onChange={(e) => setOurprojectTagline(e.target.value)}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="modal-close-btn"
                onClick={handleClose1}
              >
                Close
              </Button>
              <Button
                variant=""
                className="modal-add-btn"
                onClick={Editprojects}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>

          {/*Delet Our projects modal   */}
          <Modal
            show={show2}
            onHide={handleClose2}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <p className="fs-4" style={{ color: "red" }}>
                    Are you sure?
                    <br /> you want to delete this data?
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="modal-close-btn"
                onClick={handleClose2}
              >
                Close
              </Button>
              <Button
                variant=""
                className="modal-add-btn"
                onClick={Deletproject}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AdminOurProjects;
