import React, { useState, useEffect } from "react";
import "../Styles/servicedesc.css";
import { Button, Container } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { MdKeyboardBackspace } from "react-icons/md";
import axios from "axios";
import parse from "html-react-parser";
import { useNavigate, useLocation } from "react-router-dom";
import Aos from "aos";

const ServiceOverview = () => {
  const location = useLocation();
  const data = location.state;

  useEffect(() => {
    Aos.init();
    window.scrollTo(0, 0);
  });

  //   Selected image onclik
  const [selectedImage, setSelectedImage] = useState("../Assets/service1.jpg");

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 3, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
  };

  const [count, setCount] = useState(0);
  const [added, setAdded] = useState(false);

  const handleAddProduct = () => {
    setCount(count + 1);
    setAdded(true);
  };

  //integrating get method
  const [Addservice, setAddservice] = useState([]);
  const getAddservice = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getservice");
      if (res.status === 200) {
        setAddservice(res.data.getservice);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddservice();
  }, []);

  const [show1, setShow1] = useState();
  const handleClose1 = () => setShow1(false);
  const [selectedDescription, setSelectedDescription] = useState("");

  // Function to handle showing the modal with the selected description
  const handleShow1 = (description) => {
    setSelectedDescription(description);
    setShow1(true);
  };

  return (
    <div>
      <Container>
        {/* {Addservice?.map((item, i) => {
          return ( */}
        <div className="row mt-3 mb-3">
          <div className="col-md-6">
            {/* <div>
              <div>
                <img src={selectedImage} alt="" className="product-main-img" />
              </div>
              <div>
                <Carousel responsive={responsive} margin={5}>
                  <div
                    onClick={() => handleImageClick("../Assets/service2.jpg")}
                  >
                    <div className="side-of-images">
                      <img
                        src="../Assets/service2.jpg"
                        alt=""
                        className="product-img"
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => handleImageClick("../Assets/service3.jpg")}
                  >
                    <div className="side-of-images">
                      <img
                        src="../Assets/service3.jpg"
                        alt=""
                        className="product-img"
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => handleImageClick("../Assets/service4.jpg")}
                  >
                    <div className="side-of-images">
                      <img
                        src="../Assets/service4.jpg"
                        alt=""
                        className="product-img"
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => handleImageClick("../Assets/service5.jpg")}
                  >
                    <div className="side-of-images">
                      <img
                        src="../Assets/service5.jpg"
                        alt=""
                        className="product-img"
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => handleImageClick("../Assets/service3.jpg")}
                  >
                    <div className="side-of-images">
                      <img
                        src="../Assets/service3.jpg"
                        alt=""
                        className="product-img"
                      />
                    </div>
                  </div>
                  <div
                    onClick={() => handleImageClick("../Assets/service1.jpg")}
                  >
                    <div className="side-of-images">
                      <img
                        src="../Assets/service1.jpg"
                        alt=""
                        className="product-img"
                      />
                    </div>
                  </div>
                </Carousel>
              </div>
            </div> */}
            <div
              data-aos="zoom-in"
              data-aos-delay="50"
              data-aos-duration="3000"
            >
              <img
                alt="sericeimag"
                style={{ width: "100%" }}
                src={`https://nestwayinteriors.com/Services/${data?.ServiceImage}`}
              />
            </div>
          </div>
          <div
            className="col-md-6 d-flex flex-column"
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="3000"
          >
            <div>
              <h2 style={{ color: "#bd9d65" }}>{data.ServiceName}</h2>
            </div>
            <div>
              <p>{parse(`<div>${data.ServiceDescription}</div>`)}</p>
            </div>

            <div className="d-flex gap-3 mt-auto">
              <div>
                <a href="/">
                  <Button className="about-more-btn">
                    <MdKeyboardBackspace /> Back to Home
                  </Button>
                </a>
              </div>

              <div>
                <a href="/services">
                  <Button className="about-more-btn">
                    More Services <HiOutlineArrowLongRight />{" "}
                  </Button>
                </a>
              </div>

              <div>
                <a href="/gallery">
                  <Button className="about-more-btn">
                    Our Projects <HiOutlineArrowLongRight />{" "}
                  </Button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ServiceOverview;
