import React, { useEffect, useState } from "react";
import "../Admin/Admin.css";
import Card from "react-bootstrap/Card";
import { Button, Form, Modal, Pagination, Table } from "react-bootstrap";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import axios from "axios";
import parse from "html-react-parser";

const Dashboard = () => {
  const [show2, setShow2] = useState();
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Bookings get method
  const [Bookings, setBookings] = useState([]);
  const getBookings = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/user/getbooking");
      if (res.status === 200) {
        setBookings(res.data.getbooking);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Enquiry Get method
  const [Addgeneral, setAddgeneral] = useState([]);
  const getgeneral = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/user/getgeneral");
      if (res.status === 200) {
        setAddgeneral(res.data.getgeneral);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBookings();
    getgeneral();
  }, []);

  const [currenpage, setCurrentpage] = useState(1);
  const recordsperpage = 5;
  const lastIndex = currenpage * recordsperpage;
  const firstIndex = lastIndex - recordsperpage;
  const records = Bookings.slice(firstIndex, lastIndex);
  const npages = Math.ceil(Bookings.length / recordsperpage);
  const numbers = [...Array(npages + 1).keys()].slice(1);

  function changePage(id) {
    setCurrentpage(id);
  }

  function prevpage() {
    if (currenpage !== firstIndex) {
      setCurrentpage(currenpage - 1);
    }
  }

  function nextpage() {
    if (currenpage !== lastIndex) {
      setCurrentpage(currenpage + 1);
    }
  }
  return (
    <div>
      <h2 className="header-c ">Dashboard</h2>

      <div className="cards-container">
        <Card
          style={{
            width: "15rem",
            height: "120px",
            padding: "20px",
            boxShadow: "1px 0px 10px 1px black",
            borderRadius: "10px",
          }}
        >
          <Card.Body>
            <Card.Title>Total Bookings</Card.Title>
            {Bookings?.length}
            <Card.Subtitle className="mb-2 text-muted"></Card.Subtitle>
          </Card.Body>
        </Card>

        <Card
          style={{
            width: "15rem",
            height: "120px",
            padding: "20px",
            boxShadow: "1px 0px 10px 1px black",
            borderRadius: "10px",
          }}
        >
          <Card.Body>
            <Card.Title>Total Enquiry</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {Addgeneral?.length}
            </Card.Subtitle>
          </Card.Body>
        </Card>
      </div>

      <div>
        <div className="srch-icon">
          <div>
            <h2 className="header-c ">All Bookings</h2>
          </div>
          <div>
            <Button variant="" className="admin-add-btn">
              <a
                style={{ color: "white", textDecoration: "none" }}
                href="/admin_booking_list"
              >
                View All Bookings
              </a>
            </Button>
          </div>
        </div>
        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>SL.NO</th>
                <th>Enquiry Date</th>
                <th>Name</th>
                <th>Email ID</th>
                <th>Phone Number</th>
                <th>Message</th>
              </tr>
            </thead>

            <tbody>
              {records?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td style={{ paddingTop: "20px" }}>{i + 1}</td>
                    <td style={{ paddingTop: "20px" }}>
                      {new Date(item.BookingDate).toLocaleDateString("en-GB", {
                        day: "2-digit",
                        month: "long",
                        year: "numeric",
                      })}
                    </td>
                    <td style={{ paddingTop: "20px" }}>{item.UserName}</td>
                    <td style={{ paddingTop: "20px" }}>{item.UserEmail}</td>
                    <td style={{ paddingTop: "20px" }}>{item.UserPhone}</td>
                    <td style={{ paddingTop: "20px" }}>
                      {parse(`<div>${item.UserMessage}</div>`)}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Delet modal  */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              // onClick={Deleteuserlist}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <div></div>
    </div>
  );
};

export default Dashboard;
