import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";

const AdminSlider = () => {
  // Add modal
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Delete modal
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // integrating post method
  const formdata = new FormData();
  const [SliderImage, setSliderImage] = useState("");
  const [SliderText, setSliderText] = useState("");
  const [SliderTagline, setSliderTagline] = useState("");

  const AddSliderDetials = async () => {
    formdata.append("SliderImage", SliderImage);
    formdata.append("SliderText", SliderText);
    formdata.append("SliderTagline", SliderTagline);

    try {
      if (!SliderImage) {
        return alert("Please add Images");
      }
      const config = {
        url: "admin/homeslider",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddSlider();
        handleClose();
        setSliderImage("");
        setSliderText("");
        setSliderTagline("");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get method
  const [AddSlider, setAddSlider] = useState([]);
  const getAddSlider = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/gethomeslider"
      );
      if (res.status === 200) {
        setAddSlider(res.data.gethomeslider);
        // setNoChangeData1(res.data.gethomeslider);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const DeleteSlider = async () => {
    try {
      const config = {
        url: "admin/Deletehomeslider/" + Data,
        method: "delete",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddSlider();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
    setSliderText(item?.SliderText);
    setSliderTagline(item?.SliderTagline);
  };

  const EditSlider = async (e) => {
    e.preventDefault();
    formdata.append("SliderImage", SliderImage);
    formdata.append("SliderText", SliderText);
    formdata.append("SliderTagline", SliderTagline);
    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/edithomeslider",
        method: "put",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose3();
          getAddSlider();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getAddSlider();
  }, []);

  // Search filter
  const [nochangedata1, setNoChangeData1] = useState([]);
  const [searchH1, setSearchH1] = useState("");

  const handleFilterH = (e) => {
    const searchTerm1 = e.target.value.toLowerCase();
    setSearchH1(searchTerm1);
    if (searchTerm1 !== "") {
      const filteredData1 = nochangedata1.filter((user) =>
        Object.values(user).some((value) =>
          String(value).toLowerCase().includes(searchTerm1)
        )
      );
      setAddSlider(filteredData1);
    } else {
      setAddSlider(nochangedata1);
    }
  };
  return (
    <div>
      {/* <div className="col-lg-4 d-flex justify-content-center">
        <div class="input-group ">
          <span class="input-group-text" id="basic-addon1">
            <BsSearch />
          </span>
          <input
            type="text"
            class="form-control"
            placeholder="Search..."
            aria-describedby="basic-addon1"
            onChange={handleFilterH}
          />
        </div>
      </div> */}
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Home Slider</h2>
          <div className="d-flex gap-3">
            <div>
              {AddSlider?.length === 3 ? (
                ""
              ) : (
                <>
                  <button className="admin-add-btn" onClick={handleShow}>
                    Add Home Slider
                  </button>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Slider Image</th>
                <th>Heading</th>
                <th>Tagline</th>
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {AddSlider?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td style={{ paddingTop: "20px" }}>{i + 1}</td>
                    <td style={{ paddingTop: "20px" }}>
                      <Image
                        src={`https://nestwayinteriors.com/HomePage/${item?.SliderImage}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>

                    <td style={{ paddingTop: "20px" }}>{item.SliderText}</td>
                    <td style={{ paddingTop: "20px" }}>{item.SliderTagline}</td>

                    <td style={{ paddingTop: "20px" }}>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className="text-success"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => handleShow3(item)}
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className="text-danger"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow4();
                              setData(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Home Slider modal  */}
        <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Home Slider</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Slider Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setSliderImage(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Form Heading</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Form Heading"
                  onChange={(e) => setSliderText(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Form Tagline</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Form Tagline"
                  onChange={(e) => setSliderTagline(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={AddSliderDetials}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Home Slider modal  */}
        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Home Slider
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Slider Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  value={SliderImage}
                  onChange={(e) => setSliderImage(e.target.files[0])}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Form Heading</label>
                <input
                  type="text"
                  className="vi_0"
                  value={SliderText}
                  onChange={(e) => setSliderText(e.target.value)}
                />
              </div>
            </div>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Form Tagline</label>
                <input
                  type="text"
                  className="vi_0"
                  value={SliderTagline}
                  onChange={(e) => setSliderTagline(e.target.value)}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose3}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn" onClick={EditSlider}>
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button variant="" className="modal-add-btn" onClick={DeleteSlider}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminSlider;
