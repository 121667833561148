import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../Admin/Admin.css";
import axios from "axios";

const AdminQualityofWork = () => {
  // condition variable
  const [Quality, setQuality] = useState(true);
  const [Number, setNumber] = useState(false);

  // Add modal
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //  Edit modal
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
    setQualityworkHeading(item?.QualityworkHeading);
    setQualityworkTagline(item?.QualityworkTagline);
  };

  // Delet modal
  const [show2, setShow2] = useState();
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // integrating post method
  const [QualityworkHeading, setQualityworkHeading] = useState("");
  const [QualityworkTagline, setQualityworkTagline] = useState("");

  const Addqualitywrkdata = async () => {
    try {
      if (!QualityworkHeading) {
        return alert("Please add Heading");
      }

      const config = {
        url: "admin/qualitywork",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          QualityworkHeading: QualityworkHeading,
          QualityworkTagline: QualityworkTagline,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getQualitywork();
        handleClose();
        setQualityworkHeading("");
        setQualityworkTagline("");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get method
  const [Qualitywork, setQualitywork] = useState([]);
  const getQualitywork = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getqualitywork"
      );
      if (res.status === 200) {
        setQualitywork(res.data.getqualitywork);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const Deletqualitywrk = async () => {
    try {
      const config = {
        url: "admin/Deletequalitywork/" + Data,
        method: "delete",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getQualitywork();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const EditQualitywrk = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "admin/editqualitywork",
        method: "put",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          QualityworkHeading: QualityworkHeading,
          QualityworkTagline: QualityworkTagline,
          id: Data1?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getQualitywork();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getQualitywork();
  }, []);

  // =========================QUALITY COUNTS=========================/

  // Add modal
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //  Edit modal
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setShow4(true);
    setData3(item);
    setQualitycountsNumber(item?.QualitycountsNumber);
    setQualitycountsName(item?.QualitycountsName);
  };

  // Delet modal
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // integrating post method
  const [QualitycountsNumber, setQualitycountsNumber] = useState("");
  const [QualitycountsName, setQualitycountsName] = useState("");

  const AddQualitycount = async () => {
    try {
      if (!QualitycountsNumber) {
        return alert("Please add Number");
      }
      if (!QualitycountsName) {
        return alert("Please add Heading");
      }

      const config = {
        url: "admin/qualitycounts",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          QualitycountsNumber: QualitycountsNumber,
          QualitycountsName: QualitycountsName,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getQualitycount();
        handleClose3();
        setQualitycountsNumber("");
        setQualitycountsName("");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get method
  const [Qualitycount, setQualitycount] = useState([]);
  const getQualitycount = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getqualitycounts"
      );
      if (res.status === 200) {
        setQualitycount(res.data.getqualitycounts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data2, setData2] = useState("");
  const DeletQualitycount = async () => {
    try {
      const config = {
        url: "admin/Deletequalitycounts/" + Data2,
        method: "delete",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getQualitycount();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data3, setData3] = useState("");
  const EditQualitycount = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "admin/editqualitycounts",
        method: "put",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          QualitycountsNumber: QualitycountsNumber,
          QualitycountsName: QualitycountsName,
          id: Data3?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose4();
          getQualitycount();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getQualitycount();
  }, []);

  return (
    <div>
      <div>
        <div className="customerhead p-2">
          <div className="d-flex gap-3">
            <button
              className="admin-add-btn"
              onClick={() => {
                setQuality(true);
                setNumber(false);
              }}
            >
              Quality Of Work
            </button>
            <button
              className="admin-add-btn"
              onClick={() => {
                setQuality(false);
                setNumber(true);
              }}
            >
              Counts
            </button>
          </div>
        </div>

        {Quality ? (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="header-c "> Quality Of Work</h2>
              <div className="d-flex gap-3">
                <div>
                  {Qualitywork?.length !== 0 ? (
                    ""
                  ) : (
                    <>
                      <button className="admin-add-btn" onClick={handleShow}>
                        Add Quality Of Work
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="mb-3">
              <Table
                responsive
                bordered
                style={{ width: "-webkit-fill-available" }}
              >
                <thead>
                  <tr>
                    <th>Heading</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {Qualitywork?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ paddingTop: "20px" }}>
                          {item.QualityworkHeading}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          {item.QualityworkTagline}
                        </td>
                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <BiSolidEdit
                                className="text-success"
                                style={{ cursor: "pointer", fontSize: "20px" }}
                                onClick={() => handleShow1(item)}
                              />{" "}
                            </div>
                            <div>
                              <AiFillDelete
                                className="text-danger"
                                style={{ cursor: "pointer", fontSize: "20px" }}
                                onClick={() => {
                                  handleShow2();
                                  setData(item?._id);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            {/* Add Quality modal  */}
            <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "black" }}>
                  Add Quality Of Work
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Heading</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter Heading"
                      onChange={(e) => setQualityworkHeading(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Tagline</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter Tagline"
                      onChange={(e) => setQualityworkTagline(e.target.value)}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <Button
                    className="mx-2 modal-close-btn"
                    variant=""
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  <Button
                    className="mx-2 modal-add-btn"
                    variant=""
                    onClick={Addqualitywrkdata}
                  >
                    Add
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>

            {/* Edit Quality modal  */}
            <Modal
              show={show1}
              onHide={handleClose1}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "black" }}>
                  Edit Quality Of Work
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Heading</label>
                    <input
                      type="text"
                      className="vi_0"
                      value={QualityworkHeading}
                      onChange={(e) => setQualityworkHeading(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Tagline</label>
                    <input
                      type="text"
                      className="vi_0"
                      value={QualityworkTagline}
                      onChange={(e) => setQualityworkTagline(e.target.value)}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose1}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={EditQualitywrk}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Delet Quality modal   */}
            <Modal
              show={show2}
              onHide={handleClose2}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <p className="fs-4" style={{ color: "red" }}>
                      Are you sure?
                      <br /> you want to delete this data?
                    </p>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose2}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={Deletqualitywrk}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          <></>
        )}

        {Number ? (
          <>
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="header-c ">Quality Counts</h2>
              <div className="d-flex gap-3">
                <div>
                  {Qualitycount?.length === 4 ? (
                    ""
                  ) : (
                    <>
                      <button className="admin-add-btn" onClick={handleShow3}>
                        Add Quality Counts
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="mb-3">
              <Table
                responsive
                bordered
                style={{ width: "-webkit-fill-available" }}
              >
                <thead>
                  <tr>
                    <th>Sl.No</th>
                    <th>Number</th>
                    <th>Title</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {Qualitycount?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ paddingTop: "20px" }}>{i + 1}</td>
                        <td style={{ paddingTop: "20px" }}>
                          {item.QualitycountsNumber}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          {item.QualitycountsName}
                        </td>
                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <BiSolidEdit
                                className="text-success"
                                style={{ cursor: "pointer", fontSize: "20px" }}
                                onClick={() => handleShow4(item)}
                              />{" "}
                            </div>
                            <div>
                              <AiFillDelete
                                className="text-danger"
                                style={{ cursor: "pointer", fontSize: "20px" }}
                                onClick={() => {
                                  handleShow5();
                                  setData2(item?._id);
                                }}
                              />{" "}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            {/* Add Number modal  */}
            <Modal
              show={show3}
              onHide={handleClose3}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Quality Counts</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Quality Counts</label>
                    <input
                      type="number"
                      className="vi_0"
                      placeholder="Enter Number"
                      onChange={(e) => setQualitycountsNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Title</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter Title"
                      onChange={(e) => setQualitycountsName(e.target.value)}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <Button
                    className="mx-2 modal-close-btn"
                    variant=""
                    onClick={handleClose3}
                  >
                    Close
                  </Button>
                  <Button
                    className="mx-2 modal-add-btn"
                    variant=""
                    onClick={AddQualitycount}
                  >
                    Add
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>

            {/* Edit Number modal  */}
            <Modal
              show={show4}
              onHide={handleClose4}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Quality Counts</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Quality Counts</label>
                    <input
                      type="number"
                      className="vi_0"
                      value={QualitycountsNumber}
                      onChange={(e) => setQualitycountsNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Title</label>
                    <input
                      type="text"
                      className="vi_0"
                      value={QualitycountsName}
                      onChange={(e) => setQualitycountsName(e.target.value)}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose4}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={EditQualitycount}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Delet Number modal  */}
            <Modal
              show={show5}
              onHide={handleClose5}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <p className="fs-4" style={{ color: "red" }}>
                      Are you sure?
                      <br /> you want to delete this data?
                    </p>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleShow5}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={DeletQualitycount}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default AdminQualityofWork;
