import React, { useState, useEffect } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import parse from "html-react-parser";

const AdminHowWeWork = () => {
  // condition variable
  const [Wework, setWework] = useState(true);
  const [Workprocess, setWorkprocess] = useState(false);

  //================HOW WE WORK==========================//

  // Add modal
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //  Edit modal
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
    setHowweworkHeading(item?.HowweworkHeading);
    setHowweworkDescription(item?.HowweworkDescription);
  };

  // Delet modal
  const [show2, setShow2] = useState();
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // integrating post method
  const [HowweworkHeading, setHowweworkHeading] = useState("");
  const [HowweworkDescription, setHowweworkDescription] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setHowweworkDescription(data);
  };

  const AddHowwewrkDetails = async () => {
    try {
      if (!HowweworkHeading) {
        return alert("Please add Heading");
      }
      if (!HowweworkDescription) {
        return alert("Please add Description");
      }
      const config = {
        url: "admin/howwework",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          HowweworkHeading: HowweworkHeading,
          HowweworkDescription: HowweworkDescription,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getHowwework();
        handleClose();
        setHowweworkHeading("");
        setHowweworkDescription("");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get method
  const [Howwework, setHowwework] = useState([]);
  const getHowwework = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/gethowwework");
      if (res.status === 200) {
        setHowwework(res.data.gethowwework);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const DeleteHowwewrk = async () => {
    try {
      const config = {
        url: "admin/Deletehowwework/" + Data,
        method: "delete",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getHowwework();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const EditHowwewrk = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "admin/edithowwework",
        method: "put",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          HowweworkHeading: HowweworkHeading,
          HowweworkDescription: HowweworkDescription,
          id: Data1?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getHowwework();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getHowwework();
  }, []);

  //===================WORK PROCESS===============================//

  // Add modal
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //  Edit modal
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setShow4(true);
    setData3(item);
    setWorkprocessNumber(item?.WorkprocessNumber);
    setWorkprocessHeading(item?.WorkprocessHeading);
    setWorkprocessDescription(item?.WorkprocessDescription);
  };

  // Delet modal for Social media
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  // integrating post method
  const [WorkprocessNumber, setWorkprocessNumber] = useState("");
  const [WorkprocessHeading, setWorkprocessHeading] = useState("");
  const [WorkprocessDescription, setWorkprocessDescription] = useState("");

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setWorkprocessDescription(data);
  };

  const Addworkprocessdetail = async () => {
    try {
      if (!WorkprocessNumber) {
        return alert("Please add Number");
      }
      if (!WorkprocessHeading) {
        return alert("Please add Heading");
      }
      if (!WorkprocessDescription) {
        return alert("Please add Description");
      }
      const config = {
        url: "admin/workprocess",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          WorkprocessNumber: WorkprocessNumber,
          WorkprocessHeading: WorkprocessHeading,
          WorkprocessDescription: WorkprocessDescription,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddWorkprocess();
        handleClose3();
        setWorkprocessNumber("");
        setWorkprocessHeading("");
        setWorkprocessDescription("");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get method
  const [AddWorkprocess, setAddWorkprocess] = useState([]);
  const getAddWorkprocess = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getworkprocess"
      );
      if (res.status === 200) {
        setAddWorkprocess(res.data.getworkprocess);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data2, setData2] = useState("");
  const Deletwrkprocess = async () => {
    try {
      const config = {
        url: "admin/Deleteworkprocess/" + Data2,
        method: "delete",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddWorkprocess();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data3, setData3] = useState("");
  const Editwrkprocess = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "admin/editworkprocess",
        method: "put",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          WorkprocessNumber: WorkprocessNumber,
          WorkprocessHeading: WorkprocessHeading,
          WorkprocessDescription: WorkprocessDescription,
          id: Data3?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose4();
          getAddWorkprocess();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getAddWorkprocess();
  }, []);

  return (
    <div>
      <div className="customerhead p-2">
        <div className="d-flex gap-3">
          <button
            className="admin-add-btn"
            onClick={() => {
              setWework(true);
              setWorkprocess(false);
            }}
          >
            How we work
          </button>
          <button
            className="admin-add-btn"
            onClick={() => {
              setWework(false);
              setWorkprocess(true);
            }}
          >
            Work Process
          </button>
        </div>
      </div>

      {Wework ? (
        <>
          <div className="customerhead p-2">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="header-c ">How We Work</h2>
              <div className="d-flex gap-3">
                <div>
                  {Howwework?.length !== 0 ? (
                    ""
                  ) : (
                    <>
                      <button className="admin-add-btn" onClick={handleShow}>
                        Add How We Work
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="mb-3">
              <Table
                responsive
                bordered
                style={{ width: "-webkit-fill-available" }}
              >
                <thead>
                  <tr>
                    <th>Heading</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {Howwework?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td style={{ paddingTop: "20px" }}>
                          {item.HowweworkHeading}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          <div className="scroller">
                            {parse(`<div>${item.HowweworkDescription}</div>`)}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <BiSolidEdit
                                className="text-success"
                                style={{ cursor: "pointer", fontSize: "20px" }}
                                onClick={() => handleShow1(item)}
                              />{" "}
                            </div>
                            <div>
                              <AiFillDelete
                                className="text-danger"
                                style={{ cursor: "pointer", fontSize: "20px" }}
                                onClick={() => {
                                  handleShow2();
                                  setData(item?._id);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            {/* Add Wework modal  */}
            <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "black" }}>
                  Add How We Work
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Heading</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter Heading"
                      onChange={(e) => setHowweworkHeading(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Description</label>
                    <CKEditor editor={ClassicEditor} onChange={handleChange} />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <Button
                    className="mx-2 modal-close-btn"
                    variant=""
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                  <Button
                    className="mx-2 modal-add-btn"
                    variant=""
                    onClick={AddHowwewrkDetails}
                  >
                    Add
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>

            {/* Edit Wework modal  */}
            <Modal
              show={show1}
              onHide={handleClose1}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title style={{ color: "black" }}>
                  Edit How We Work
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Heading</label>
                    <input
                      type="text"
                      className="vi_0"
                      value={HowweworkHeading}
                      onChange={(e) => setHowweworkHeading(e.target.value)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={HowweworkDescription}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose1}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={EditHowwewrk}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Delet Wework modal   */}
            <Modal
              show={show2}
              onHide={handleClose2}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <p className="fs-4" style={{ color: "red" }}>
                      Are you sure?
                      <br /> you want to delete this data?
                    </p>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose2}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={DeleteHowwewrk}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
      ) : (
        <></>
      )}

      {Workprocess ? (
        <>
          <div className="customerhead p-2">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="header-c ">Work Process</h2>
              <div className="d-flex gap-3">
                <div>
                  {AddWorkprocess?.length === 3 ? (
                    ""
                  ) : (
                    <>
                      <button className="admin-add-btn" onClick={handleShow3}>
                        Add Work Process
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="mb-3">
              <Table
                responsive
                bordered
                style={{ width: "-webkit-fill-available" }}
              >
                <thead>
                  <tr>
                    {/* <th>Sl.No</th> */}
                    <th>Number</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {AddWorkprocess?.map((item, i) => {
                    return (
                      <tr key={i}>
                        {/* <td style={{ paddingTop: "20px" }}>{i + 1}</td> */}
                        <td style={{ paddingTop: "20px" }}>
                          {item.WorkprocessNumber}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          {item.WorkprocessHeading}
                        </td>
                        <td style={{ paddingTop: "20px" }}>
                          <div className="scroller">
                            {parse(`<div>${item.WorkprocessDescription}</div>`)}
                          </div>
                        </td>
                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <BiSolidEdit
                                className="text-success"
                                style={{ cursor: "pointer", fontSize: "20px" }}
                                onClick={() => handleShow4(item)}
                              />{" "}
                            </div>
                            <div>
                              <AiFillDelete
                                className="text-danger"
                                style={{ cursor: "pointer", fontSize: "20px" }}
                                onClick={() => {
                                  handleShow5();
                                  setData2(item?._id);
                                }}
                              />{" "}
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>

            {/* Add Workprocess modal  */}
            <Modal
              show={show3}
              onHide={handleClose3}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Add Work Process</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Number</label>
                    <input
                      type="number"
                      className="vi_0"
                      placeholder="Enter Number"
                      onChange={(e) => setWorkprocessNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Title</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter Title"
                      onChange={(e) => setWorkprocessHeading(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Description</label>
                    <CKEditor editor={ClassicEditor} onChange={handleChange1} />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <Button
                    className="mx-2 modal-close-btn"
                    variant=""
                    onClick={handleClose3}
                  >
                    Close
                  </Button>
                  <Button
                    className="mx-2 modal-add-btn"
                    variant=""
                    onClick={Addworkprocessdetail}
                  >
                    Add
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>

            {/* Edit Workprocess modal  */}
            <Modal
              show={show4}
              onHide={handleClose4}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Edit Work Process</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Number</label>
                    <input
                      type="number"
                      className="vi_0"
                      value={WorkprocessNumber}
                      onChange={(e) => setWorkprocessNumber(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Title</label>
                    <input
                      type="text"
                      className="vi_0"
                      value={WorkprocessHeading}
                      onChange={(e) => setWorkprocessHeading(e.target.value)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={WorkprocessDescription}
                      onChange={handleChange1}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose4}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={Editwrkprocess}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>

            {/* Delet Workprocess modal  */}
            <Modal
              show={show5}
              onHide={handleClose5}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <p className="fs-4" style={{ color: "red" }}>
                      Are you sure?
                      <br /> you want to delete this data?
                    </p>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleShow5}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                  onClick={Deletwrkprocess}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AdminHowWeWork;
