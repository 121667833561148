import React, { useState, useEffect } from "react";
import "../Styles/servicedesc.css";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa6";
import axios from "axios";
import Aos from "aos";

const Services = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };

  useEffect(() => {
    Aos.init();
  });

  // Scrool window icon

  const [visible, setVisible] = useState(false);
  const [atBottom, setAtBottom] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    const maxScroll =
      document.documentElement.scrollHeight - window.innerHeight;
    if (scrolled > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    if (scrolled >= maxScroll) {
      setAtBottom(true);
    } else {
      setAtBottom(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  //======================OUR SERVICES============================//
  //integrating get method
  const [Addservice, setAddservice] = useState([]);
  const getAddservice = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getservice");
      if (res.status === 200) {
        setAddservice(res.data.getservice);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddservice();
  }, []);

  return (
    <div>
      {/* Common Banner  */}

      <div className="servicebackground-image-container">
        <div className="overlay">
          <div>
            <h2 className="servicebg-title" style={{ color: "#bd9d65" }}>
              SERVICES
            </h2>
            <br />
            <div className="nav-path-service">
              <div onClick={handleClick}>Home / </div>&nbsp;
              <div style={{ color: "#bd9d65" }}> Services</div>
            </div>
          </div>

          <div
            className="fixed-icon"
            style={{ border: "none", backgroundColor: "unset !important" }}
          >
            <div>
              <a
                href="//"
                target="_new"
                style={{ color: "unset", textDecoration: "none" }}
              >
                <img
                  src="../Assets/WhatsApp_icon.png"
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
                <span className="tooltip-text">Let's chat</span>
              </a>
            </div>
          </div>

          <div>
            <div
              className="scrool-up-icon"
              style={{ display: visible ? "inline" : "none" }}
            >
              <div className="scrool mb-2" onClick={scrollToTop}>
                <FaArrowCircleUp
                  style={{ fontSize: "40px", color: "#106b87" }}
                />
              </div>

              <div className="scrool" onClick={scrollToBottom}>
                <FaArrowCircleDown
                  style={{ fontSize: "40px", color: "#106b87" }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Container fluid>
        <div className="row mt-5 mb-3">
          {Addservice?.map((item, i) => {
            return (
              <div
                className="col-md-3  mb-2"
                data-aos="zoom-in"
                data-aos-delay="50"
                data-aos-duration="3000"
              >
                {/* <a href="/service-overview"> */}
                <div
                  className="card service-crd"
                  onClick={() => navigate("/service-overview", { state: item })}
                >
                  <div className="image-box">
                    <img
                      src={`https://nestwayinteriors.com/Services/${item?.ServiceImage}`}
                      alt=""
                      className="homepage-service-img"
                    />
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <div className="service-names">{item.ServiceName}</div>
                      <div className="shake">
                        <FaArrowRight />
                      </div>
                    </div>
                  </div>
                </div>
                {/* </a> */}
              </div>
            );
          })}
        </div>
      </Container>
    </div>
  );
};

export default Services;
