import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../Styles/header.css";
import { Button, Modal } from "react-bootstrap";
import { FaLocationDot } from "react-icons/fa6";
import { IoMailSharp } from "react-icons/io5";
import { BiSolidPhoneCall } from "react-icons/bi";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import axios from "axios";

function Header() {
  // =======================TOP HEADER===========================//
  const [Addcontactus, setAddcontactus] = useState([]);
  const getAddcontactus = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getcontactus");
      if (res.status === 200) {
        setAddcontactus(res.data.getcontactus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddcontactus();
  }, []);

  // =======================BOOKING APPOINTMENT=========================//
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (value) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(value);
  };

  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setUserPhone(value);
    setIsPhoneValid(validatePhoneNumber(value));
  };

  const [isNameValid, setIsNameValid] = useState(true);

  const validateName = (name) => {
    const namePattern = /^[a-zA-Z\s-]+$/;
    return namePattern.test(name);
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    setUserName(name);
    if (validateName(name)) {
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }
  };

  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const formdata = new FormData();
  const [UserName, setUserName] = useState("");
  const [UserPhone, setUserPhone] = useState("");
  const [UserEmail, setUserEmail] = useState("");
  const [UserMessage, setUserMessage] = useState("");
  const [BookingDate, setBookingDate] = useState("");

  const AddBookinglist = async () => {
    try {
      if (!UserName) {
        return alert("Please add name");
      }
      if (!UserEmail) {
        return alert("Please add email ");
      }
      if (!UserPhone) {
        return alert("Please add phone number");
      }
      if (!UserMessage) {
        return alert("Please add your message");
      }

      const currentDate = new Date().toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });

      const config = {
        url: "user/booking",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          UserName: UserName,
          UserPhone: UserPhone,
          UserEmail: UserEmail,
          UserMessage: UserMessage,
          BookingDate: currentDate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getBookings();
        setUserName("");
        setUserPhone("");
        setUserEmail("");
        setUserMessage("");
        handleClose();
        // window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  const [Bookings, setBookings] = useState([]);
  const getBookings = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/user/getbooking");
      if (res.status === 200) {
        setBookings(res.data.getbooking);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBookings();
  }, []);

  // ======================= Scroll Event Handling ==========================//
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`headers ${scrolling ? "scrolled" : ""}`}
      style={{
        position: "sticky",
        top: "0px",
        zIndex: "999",
        backgroundColor: "white",
        transition: "all 0.3s ease-in-out", // Add smooth transition
      }}
    >
      {/* Top Nav Start */}
      {!scrolling && (
        <div className={`top-nav-header`}>
          {Addcontactus?.map((item, i) => (
            <div className="top-nav  mobiledisnone" key={i}>
              <div className="navbar-right-content">
                <div className="location">
                  <FaLocationDot
                    className="hithere"
                    style={{ color: "#274C3B", fontSize: "30px" }}
                  />{" "}
                  {item.WorkLocation}
                </div>
              </div>
              <div className="navbar-left-content">
                <div>
                  <IoMailSharp
                    className="hithere"
                    style={{ color: "#274C3B", fontSize: "30px" }}
                  />{" "}
                  {item.CEmail}
                </div>
                <div>
                  <BiSolidPhoneCall
                    className="hithere"
                    style={{ color: "#274C3B", fontSize: "30px" }}
                  />{" "}
                  {item.CPhone}
                </div>
                <div className="d-block">
                  <Button
                    variant=""
                    onClick={handleShow}
                    style={{ backgroundColor: "#274C3B", color: "white" }}
                  >
                    BOOK APPOINTMENT <HiOutlineArrowLongRight />
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {/* Top Nav End */}

      {["xl"].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className={`navbar ${scrolling ? "small-navbar" : ""}`}
        >
          <Container fluid>
            <Nav.Link href="/" className="tail-text m-1">
              <a href="/">
                <img
                  src="../Assets/nestwaylogo.jpeg"
                  alt="logo"
                  className={`nav-logo-mbl ${scrolling ? "small-logo" : ""}`}
                />
              </a>
            </Nav.Link>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton></Offcanvas.Header>
              <Offcanvas.Body style={{ padding: "2px 60px" }}>
                <Nav
                  className="justify-content-end flex-grow-1 pe-3"
                  style={{ alignItems: "center" }}
                >
                  <Nav.Link href="/" className="tail-text">
                    HOME
                  </Nav.Link>
                  <Nav.Link href="/aboutus" className="tail-text">
                    ABOUT US
                  </Nav.Link>
                  <Nav.Link href="/services" className="tail-text">
                    SERVICES
                  </Nav.Link>
                  <Nav.Link href="/gallery" className="tail-text">
                    GALLERY
                  </Nav.Link>
                  <Nav.Link href="/contactus" className="tail-text">
                    CONTACT US
                  </Nav.Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}

      {/* BOOK APPOINTMENT MODAL */}
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header closeButton>
          <Modal.Title>BOOK APPOINTMENT</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Name</label>
            <input
              type="text"
              className={`form-control ${isNameValid ? "" : "is-invalid"}`}
              placeholder="Your name"
              value={UserName}
              onChange={handleNameChange}
            />
            {!isNameValid && (
              <div className="invalid-feedback">Invalid name format</div>
            )}
          </div>
          <div className="form-group">
            <label>Phone</label>
            <input
              type="text"
              className={`form-control ${isPhoneValid ? "" : "is-invalid"}`}
              placeholder="Your phone number"
              value={UserPhone}
              onChange={handlePhoneChange}
            />
            {!isPhoneValid && (
              <div className="invalid-feedback">
                Phone number must be 10 digits
              </div>
            )}
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              className={`form-control ${isEmailValid ? "" : "is-invalid"}`}
              placeholder="Your email"
              value={UserEmail}
              onChange={(e) => {
                setUserEmail(e.target.value);
                setIsEmailValid(validateEmail(e.target.value));
              }}
            />
            {!isEmailValid && (
              <div className="invalid-feedback">Invalid email format</div>
            )}
          </div>
          <div className="form-group">
            <label>Message</label>
            <textarea
              className="form-control"
              rows="3"
              value={UserMessage}
              onChange={(e) => setUserMessage(e.target.value)}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={AddBookinglist}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Header;
