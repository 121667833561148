import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

const AdminWhyChooseUs = () => {
  // condition variable
  const [Whychooseus, setWhychooseus] = useState(true);
  const [Service, setService] = useState(false);

  // ================WHY CHOOSE US===========================//

  // Add modal
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //  Edit modal
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = (item) => {
    setShow1(true);
    setData1(item);
    setWhyusHeading(item?.WhyusHeading);
    setWhyusTagline(item?.WhyusTagline);
  };

  // Delet modal for Social media
  const [show2, setShow2] = useState();
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // integrating post method
  const [WhyusHeading, setWhyusHeading] = useState("");
  const [WhyusTagline, setWhyusTagline] = useState("");

  const Addychusus = async () => {
    try {
      if (!WhyusHeading) {
        return alert("Please add Heading");
      }

      const config = {
        url: "admin/whychooseus",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          WhyusHeading: WhyusHeading,
          WhyusTagline: WhyusTagline,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddwhyus();
        handleClose();
        setWhyusHeading("");
        setWhyusTagline("");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get method
  const [Addwhyus, setAddwhyus] = useState([]);
  const getAddwhyus = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getwhychooseus"
      );
      if (res.status === 200) {
        setAddwhyus(res.data.getwhychooseus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const Deletwhyus = async () => {
    try {
      const config = {
        url: "admin/Deletewhychooseus/" + Data,
        method: "delete",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddwhyus();
          handleClose2();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const Editwhyus = async (e) => {
    e.preventDefault();
    try {
      const config = {
        url: "admin/editwhychooseus",
        method: "put",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          WhyusHeading: WhyusHeading,
          WhyusTagline: WhyusTagline,
          id: Data1?._id,
        },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose1();
          getAddwhyus();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getAddwhyus();
  }, []);

  // ========================OUR WORK=========================//

  // Add modal
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  //  Edit modal
  const [show4, setShow4] = useState(false);
  const handleClose4 = () => setShow4(false);
  const handleShow4 = (item) => {
    setShow4(true);
    setData3(item);
    setOurworkImage(item?.OurworkImage);
    setOurworkHeading(item?.OurworkHeading);
    setOurworkDescription(item?.OurworkDescription);
  };

  // Delet modal for Social media
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);



  const formdata = new FormData();
  const [OurworkImage, setOurworkImage] = useState("");
  const [OurworkHeading, setOurworkHeading] = useState("");
  const [OurworkDescription, setOurworkDescription] = useState("");

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setOurworkDescription(data);
  };

  const Addourwrkdetails = async () => {
    // Initialize FormData
   
    formdata.append("OurworkImage", OurworkImage);
    formdata.append("OurworkHeading", OurworkHeading);
    formdata.append("OurworkDescription", OurworkDescription);

    try {
      // Validate fields before submission
      if (!OurworkImage) return alert("Please add Image");
      if (!OurworkHeading) return alert("Please add Heading");
      if (!OurworkDescription) return alert("Please add Description");

      const config = {
        url: "/admin/ourwork",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api",
        headers: { "Content-Type": "multipart/form-data" },
        data: formdata,
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getOurwork(); // Refresh the data
        handleClose3(); // Close any modal or pop-up
        setOurworkImage("");
        setOurworkHeading("");
        setOurworkDescription("");
      }
    } catch (error) {
      console.log(error);
      // Show error message if present
      alert(error.response?.data?.msg || "An error occurred");
    
    }
  };



  //integrating get method
  const [Ourwork, setOurwork] = useState([]);
  const getOurwork = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getourwork");
      if (res.status === 200) {
        setOurwork(res.data.getourwork);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data2, setData2] = useState("");
  const Deletourwrk = async () => {
    try {
      const config = {
        url: "admin/Deleteourwork/" + Data2,
        method: "delete",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getOurwork();
          handleClose5();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data3, setData3] = useState("");
  const Editwrkprocess = async (e) => {
    e.preventDefault();
    formdata.append("OurworkImage", OurworkImage);
    formdata.append("OurworkHeading", OurworkHeading);
    formdata.append("OurworkDescription", OurworkDescription);
    formdata.append("id", Data3?._id);

    console.log("Data3", formdata);

    try {
      const config = {
        url: "admin/editourwork",
        method: "put",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose4();
          getOurwork();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getOurwork();
  }, []);

  return (
    <div>
      <div className="customerhead p-2">
        <div className="d-flex gap-3">
          <button
            className="admin-add-btn"
            onClick={() => {
              setWhychooseus(true);
              setService(false);
            }}
          >
            Why Choose Us
          </button>
          <button
            className="admin-add-btn"
            onClick={() => {
              setWhychooseus(false);
              setService(true);
            }}
          >
            Our Works
          </button>
        </div>
      </div>

      {Whychooseus ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="header-c "> Why Choose Us</h2>
            <div className="d-flex gap-3">
              <div>
                {Addwhyus?.length !== 0 ? (
                  ""
                ) : (
                  <>
                    <button className="admin-add-btn" onClick={handleShow}>
                      Add Why Choose Us
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="mb-3">
            <Table
              responsive
              bordered
              style={{ width: "-webkit-fill-available" }}
            >
              <thead>
                <tr>
                  <th>Heading</th>
                  <th>Tagline</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {Addwhyus?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ paddingTop: "20px" }}>
                        {item.WhyusHeading}
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        {item.WhyusTagline}
                      </td>
                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <BiSolidEdit
                              className="text-success"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => handleShow1(item)}
                            />{" "}
                          </div>
                          <div>
                            <AiFillDelete
                              className="text-danger"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => {
                                handleShow2();
                                setData(item?._id);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Add Whychooseus modal  */}
          <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black" }}>
                Add Why Choose Us
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Heading</label>
                  <input
                    type="text"
                    className="vi_0"
                    placeholder="Enter Heading"
                    onChange={(e) => setWhyusHeading(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Tagline</label>
                  <input
                    type="text"
                    className="vi_0"
                    placeholder="Enter Tagline"
                    onChange={(e) => setWhyusTagline(e.target.value)}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <Button
                  className="mx-2 modal-close-btn"
                  variant=""
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  className="mx-2 modal-add-btn"
                  variant=""
                  onClick={Addychusus}
                >
                  Add
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* Edit Whychooseus modal  */}
          <Modal
            show={show1}
            onHide={handleClose1}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black" }}>
                Edit Why Choose Us
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Heading</label>
                  <input
                    type="text"
                    className="vi_0"
                    value={WhyusHeading}
                    onChange={(e) => setWhyusHeading(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Tagline</label>
                  <input
                    type="text"
                    className="vi_0"
                    value={WhyusTagline}
                    onChange={(e) => setWhyusTagline(e.target.value)}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="modal-close-btn"
                onClick={handleClose1}
              >
                Close
              </Button>
              <Button variant="" className="modal-add-btn" onClick={Editwhyus}>
                Update
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Delet Whychooseus modal   */}
          <Modal
            show={show2}
            onHide={handleClose2}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <p className="fs-4" style={{ color: "red" }}>
                    Are you sure?
                    <br /> you want to delete this data?
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="modal-close-btn"
                onClick={handleClose2}
              >
                Close
              </Button>
              <Button variant="" className="modal-add-btn" onClick={Deletwhyus}>
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <></>
      )}

      {Service ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="header-c ">Our Works</h2>
            <div className="d-flex gap-3">
              <div>
                {Ourwork?.length === 4 ? (
                  ""
                ) : (
                  <>
                    <button className="admin-add-btn" onClick={handleShow3}>
                      Add Our Works
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="mb-3">
            <Table
              responsive
              bordered
              style={{ width: "-webkit-fill-available" }}
            >
              <thead>
                <tr>
                  <th>Sl.No</th>
                  <th>Image</th>
                  <th>Heading</th>
                  <th>Description</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {Ourwork?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ paddingTop: "20px" }}>{i + 1}</td>
                      <td style={{ paddingTop: "20px" }}>
                        <Image
                          src={`https://nestwayinteriors.com/HomePage/${item?.OurworkImage}`}
                          alt="pic"
                          style={{ width: "75px", height: "75px" }}
                        />
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        {item.OurworkHeading}
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        <div className="scroller">
                          {parse(`<div>${item.OurworkDescription}</div>`)}
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <BiSolidEdit
                              className="text-success"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => handleShow4(item)}
                            />{" "}
                          </div>
                          <div>
                            <AiFillDelete
                              className="text-danger"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => {
                                handleShow5();
                                setData2(item?._id);
                              }}
                            />{" "}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Add Service modal  */}
          <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
            <Modal.Header closeButton>
              <Modal.Title>Add Our Works</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Image</label>
                  <input
                    type="file"
                    id=""
                    className="vi_0"
                    onChange={(e) => setOurworkImage(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Heading</label>
                  <input
                    type="text"
                    className="vi_0"
                    placeholder="Enter Heading"
                    onChange={(e) => setOurworkHeading(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Description</label>
                  <CKEditor editor={ClassicEditor} onChange={handleChange1} />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <Button
                  className="mx-2 modal-close-btn"
                  variant=""
                  onClick={handleClose3}
                >
                  Close
                </Button>
                <Button
                  className="mx-2 modal-add-btn"
                  variant=""
                  onClick={Addourwrkdetails}
                >
                  Add
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* Edit Service modal  */}
          <Modal
            show={show4}
            onHide={handleClose4}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Our Works</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Image</label>
                  <input
                    type="file"
                    id=""
                    className="vi_0"
                    onChange={(e) => setOurworkImage(e.target.files[0])}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Heading</label>
                  <input
                    type="text"
                    className="vi_0"
                    value={OurworkHeading}
                    onChange={(e) => setOurworkHeading(e.target.value)}
                  />
                </div>
              </div>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={OurworkDescription}
                    onChange={handleChange1}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="modal-close-btn"
                onClick={handleClose4}
              >
                Close
              </Button>
              <Button
                variant=""
                className="modal-add-btn"
                onClick={Editwrkprocess}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Delet Service modal  */}
          <Modal
            show={show5}
            onHide={handleClose5}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <p className="fs-4" style={{ color: "red" }}>
                    Are you sure?
                    <br /> you want to delete this data?
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="modal-close-btn"
                onClick={handleShow5}
              >
                Close
              </Button>
              <Button
                variant=""
                className="modal-add-btn"
                onClick={Deletourwrk}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AdminWhyChooseUs;
