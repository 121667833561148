import React, { useState, useEffect } from "react";
import "../Styles/footer.css";
import { IoMdMail } from "react-icons/io";
import { BiSolidPhoneCall } from "react-icons/bi";
import { Container, Button, Modal } from "react-bootstrap";
import { MdKeyboardArrowRight } from "react-icons/md";
import axios from "axios";
import { FaBuilding } from "react-icons/fa";
import Aos from "aos";

const Footer = () => {
  useEffect(() => {
    Aos.init();
  });

  //integrating get of contact us
  const [Addcontactus, setAddcontactus] = useState([]);
  const getAddcontactus = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getcontactus");
      if (res.status === 200) {
        setAddcontactus(res.data.getcontactus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddcontactus();
  }, []);

  //integrating get method of social media
  const [Addsocial, setAddsocial] = useState([]);
  const getAddsocial = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getsocial");
      if (res.status === 200) {
        setAddsocial(res.data.getsocial);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddsocial();
  }, []);

  //integrating get method of common footer
  const [Commonfooter, setCommonfooter] = useState([]);
  const getCommonfooter = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getcommonfooter"
      );
      if (res.status === 200) {
        setCommonfooter(res.data.getcommonfooter);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCommonfooter();
  }, []);

  return (
    <div>
      <div className="quality-container2 mt-3">
        <div className="overlay2">
          <Container>
            {Commonfooter?.map((item, i) => {
              return (
                <div
                  className="text-center"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                  data-aos-duration="3000"
                >
                  <div className="quality-of-wrk-title">
                    <h5 style={{ color: "#bd9d65" }}>
                      YOU DREAM IT, WE DESIGN IT
                    </h5>
                  </div>
                  <div className="dream-it-subtitle">
                    <h2>{item.CommonfooterHeading}</h2>
                  </div>
                  <div>
                    <a href="/contactus">
                      <Button
                        className="mt-3"
                        variant=""
                        style={{
                          border: "none",
                          fontSize: "22px",
                          backgroundColor: "#bd9d65",
                          color: "black",
                        }}
                      >
                        Contact us
                      </Button>
                    </a>
                  </div>
                </div>
              );
            })}
          </Container>
        </div>
      </div>
      <div className="footer-section w-100 ">
        <Container>
          <div className="row mt-4 R-margins">
            <div className="col-md-3 mt-2 mb-3 R-margins">
              <div>
                <img
                  src="../Assets/nestwaylogo.jpeg"
                  alt="logo"
                  className="footer-logo"
                />
              </div>
            </div>

            <div className="col-md-3 R-margins text-center">
              <div className="quick">
                <h4 className="footer-title">Quick Links</h4>

                <ul className="quick">
                  <li>
                    <div className="d-flex gap-2 mb-2">
                      <div>
                        <span>
                          <MdKeyboardArrowRight
                            style={{ color: "#BC9E62", fontSize: "20px" }}
                          />
                        </span>
                      </div>
                      <a href="/aboutus">
                        <div style={{ color: "#BC9E62" }}>About us</div>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex gap-2 mb-3 R-margins">
                      <div>
                        <span>
                          <MdKeyboardArrowRight
                            style={{ color: "#BC9E62", fontSize: "20px" }}
                          />
                        </span>
                      </div>
                      <a href="/services">
                        <div style={{ color: "#BC9E62" }}>Services</div>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex gap-2 mb-3 R-margins">
                      <div>
                        <span>
                          <MdKeyboardArrowRight
                            style={{ color: "#BC9E62", fontSize: "20px" }}
                          />
                        </span>
                      </div>
                      <a href="/gallery">
                        <div style={{ color: "#BC9E62" }}>Gallery</div>
                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="d-flex gap-2 mb-3 R-margins">
                      <div>
                        <span>
                          <MdKeyboardArrowRight
                            style={{ color: "#BC9E62", fontSize: "20px" }}
                          />
                        </span>
                      </div>
                      <a href="/contactus">
                        <div style={{ color: "#BC9E62" }}>Contact us</div>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div
              className="col-md-3 R-margins footer-social-icon"
              style={{ display: "flex" }}
            >
              <div className="quick">
                <h4 className="" style={{ color: "#BC9E62" }}>
                  Follow Us
                </h4>
                <div className="social-icons">
                  {Addsocial?.map((item, i) => {
                    return (
                      <div className=" mb-2" key={i}>
                        <a
                          href={item.CLink}
                          target="_new"
                          rel="noopener noreferrer"
                        >
                          <img
                            src={`https://nestwayinteriors.com/SocialMedia/${item?.CIcon}`}
                            alt=""
                            className="s-img"
                          />
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="col-md-3 R-margins text-center">
              <div className="quick">
                <h4 className="footer-title">Contact Us</h4>
                {Addcontactus?.map((item, i) => {
                  return (
                    <ul className="quick">
                      <li>
                        <div className="d-flex gap-3 mb-2">
                          <div>
                            <span>
                              <FaBuilding
                                style={{ color: "#BC9E62", fontSize: "24px" }}
                              />
                            </span>
                          </div>
                          <div style={{ color: "#BC9E62" }}>
                            {item.CAddress}
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex gap-3 mb-2">
                          <div>
                            <span>
                              <IoMdMail
                                style={{ color: "#BC9E62", fontSize: "24px" }}
                              />
                            </span>
                          </div>
                          <div style={{ color: "#BC9E62" }}>{item.CEmail}</div>
                        </div>
                      </li>
                      <li>
                        <div className="d-flex gap-3 mb-3 R-margins">
                          <div>
                            <span>
                              <BiSolidPhoneCall
                                style={{ color: "#BC9E62", fontSize: "28px" }}
                              />
                            </span>
                          </div>
                          <div style={{ color: "#BC9E62", textAlign: "left" }}>
                            {item.CPhone}
                          </div>
                        </div>
                      </li>
                    </ul>
                  );
                })}
              </div>
            </div>

            {/* <div
              className="col-md-3 R-margins footer-social-icon"
              style={{ display: "flex", justifyContent: "center", marginTop:"38px" }}
            >
              <div className="quick">
                <h4 className="footer-title">Share Your Review</h4>
                <ul>
                  <li>
                    <div>
                      <Button >Add Review</Button>
                    </div>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
