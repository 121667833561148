import "./App.css";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./Components/Header";
import Home from "./Components/Home";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ServiceOverview from "./Components/ServiceOverview";
import Footer from "./Components/Footer";
import Aboutus from "./Components/Aboutus";
import Services from "./Components/Services";
import Gallery from "./Components/Gallery";
import Contactus from "./Components/Contactus";

// ADMIN PANEL
import AdminLogin from "./Components/Admin/AdminLogin";
import Dashboard from "./Components/Admin/Dashboard";
import Main from "./Components/Admin/Main";
import Slider from "./Components/Admin/AdminSlider";
import AdminHowWeWork from "./Components/Admin/AdminHowWeWork";
import AdminOurProjects from "./Components/Admin/AdminOurProjects";
import AdminWhyChooseUs from "./Components/Admin/AdminWhyChooseUs";
import AdminQualityofWork from "./Components/Admin/AdminQualityofWork";
import AdminAboutus from "./Components/Admin/AdminAboutus";
import AdminBestofus from "./Components/Admin/AdminBestofus";
import AdminVisionMission from "./Components/Admin/AdminVisionMission";
import AdminWhatweOffer from "./Components/Admin/AdminWhatweOffer";
import AdminServices from "./Components/Admin/AdminServices";
import AdminGallery from "./Components/Admin/AdminGallery";
import AdminCommonFooter from "./Components/Admin/AdminCommonFooter";
import AdminContactus from "./Components/Admin/AdminContactus";
import GeneralEnquiry from "./Components/Admin/GeneralEnquiry";
import BookingList from "./Components/Admin/BookingList";
import PackageEnquiry from "./Components/Admin/PackageEnquiry";
import Testimonials from "./Components/Admin/Testimonials";

function App() {
  return (
    <div className="App">
      <>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Header />
                  <Home />
                  <Footer />
                </>
              }
            />

            <Route
              path="/aboutus"
              element={
                <>
                  <Header />
                  <Aboutus />
                  <Footer />
                </>
              }
            />

            <Route
              path="/services"
              element={
                <>
                  <Header />
                  <Services />
                  <Footer />
                </>
              }
            />

            <Route
              path="/service-overview"
              element={
                <>
                  <Header />
                  <ServiceOverview />
                  <Footer />
                </>
              }
            />

            <Route
              path="/gallery"
              element={
                <>
                  <Header />
                  <Gallery />
                  <Footer />
                </>
              }
            />

            <Route
              path="/contactus"
              element={
                <>
                  <Header />
                  <Contactus />
                  <Footer />
                </>
              }
            />

            {/* Admin panel  */}
            <Route
            path="/admin"
            element={
              <>
                <AdminLogin />
              </>
            }
          />



            <Route
              path="/dashboard"
              element={
                <Main
                  children={
                    <>
                      <Dashboard />
                    </>
                  }
                />
              }
            />

            <Route
              path="/home_slider"
              element={
                <Main
                  children={
                    <>
                      <Slider />
                    </>
                  }
                />
              }
            />

            <Route
              path="/home_how_we_work"
              element={
                <Main
                  children={
                    <>
                      <AdminHowWeWork />
                    </>
                  }
                />
              }
            />

            <Route
              path="/home_our_projects"
              element={
                <Main
                  children={
                    <>
                      <AdminOurProjects />
                    </>
                  }
                />
              }
            />

            <Route
              path="/home_why_choose_us"
              element={
                <Main
                  children={
                    <>
                      <AdminWhyChooseUs />
                    </>
                  }
                />
              }
            />

            <Route
              path="/home_quality-of-work"
              element={
                <Main
                  children={
                    <>
                      <AdminQualityofWork />
                    </>
                  }
                />
              }
            />

            <Route
              path="/about_us"
              element={
                <Main
                  children={
                    <>
                      <AdminAboutus />
                    </>
                  }
                />
              }
            />
            <Route
              path="/best_of_us"
              element={
                <Main
                  children={
                    <>
                      <AdminBestofus />
                    </>
                  }
                />
              }
            />

            <Route
              path="/vision_mission"
              element={
                <Main
                  children={
                    <>
                      <AdminVisionMission />
                    </>
                  }
                />
              }
            />
            <Route
              path="/what_we_offer"
              element={
                <Main
                  children={
                    <>
                      <AdminWhatweOffer />
                    </>
                  }
                />
              }
            />

            <Route
              path="/admin_services"
              element={
                <Main
                  children={
                    <>
                      <AdminServices />
                    </>
                  }
                />
              }
            />

            <Route
              path="/admin_gallery"
              element={
                <Main
                  children={
                    <>
                      <AdminGallery />
                    </>
                  }
                />
              }
            />
            <Route
              path="/admin_common_footer"
              element={
                <Main
                  children={
                    <>
                      <AdminCommonFooter />
                    </>
                  }
                />
              }
            />
            <Route
              path="/admin_contactus"
              element={
                <Main
                  children={
                    <>
                      <AdminContactus />
                    </>
                  }
                />
              }
            />
            <Route
              path="/package_enquiry"
              element={
                <Main
                  children={
                    <>
                      <PackageEnquiry />
                    </>
                  }
                />
              }
            />
            <Route
              path="/admin_general_enquiry"
              element={
                <Main
                  children={
                    <>
                      <GeneralEnquiry />
                    </>
                  }
                />
              }
            />
            <Route
              path="/admin_booking_list"
              element={
                <Main
                  children={
                    <>
                      <BookingList />
                    </>
                  }
                />
              }
            />
             <Route
              path="/testimonial"
              element={
                <Main
                  children={
                    <>
                      <Testimonials />
                    </>
                  }
                />
              }
            />
          </Routes>
        </BrowserRouter>
      </>
    </div>
  );
}

export default App;
