import React, { useState, useEffect } from "react";
import "../Styles/homeslider.css";
import Carousel from "react-bootstrap/Carousel";
import { Button, Modal } from "react-bootstrap";
import { FaArrowCircleDown, FaArrowCircleUp } from "react-icons/fa";
import axios from "axios";
import Aos from "aos";

const HomeSlider = () => {
  // AOS ANIMATION
  useEffect(() => {
    Aos.init();
  });

  // Scrool window icon

  const [visible, setVisible] = useState(false);
  const [atBottom, setAtBottom] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    const maxScroll =
      document.documentElement.scrollHeight - window.innerHeight;
    if (scrolled > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    if (scrolled >= maxScroll) {
      setAtBottom(true);
    } else {
      setAtBottom(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);
    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  //integrating get method of Slider
  const [AddSlider, setAddSlider] = useState([]);
  const getAddSlider = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/gethomeslider"
      );
      if (res.status === 200) {
        setAddSlider(res.data.gethomeslider);
        // setNoChangeData1(res.data.gethomeslider);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddSlider();
  }, []);

  // PACKAGE ENQUIRY
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Email formate Validation
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);

  const validateEmail = (value) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailPattern.test(value);
  };

  // Phone number validation
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const validatePhoneNumber = (number) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(number);
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPUserPhone(value);
    setIsPhoneValid(validatePhoneNumber(value));
  };

  // name validation
  const [isNameValid, setIsNameValid] = useState(true);

  const validateName = (name) => {
    const namePattern = /^[a-zA-Z\s-]+$/;
    return namePattern.test(name);
  };

  const handleNameChange = (e) => {
    const name = e.target.value;
    setPUserName(name);

    // Validate name
    if (validateName(name)) {
      setIsNameValid(true);
    } else {
      setIsNameValid(false);
    }
  };

  // post method form
  const formdata = new FormData();
  const [PUserName, setPUserName] = useState("");
  const [PUserPhone, setPUserPhone] = useState("");
  const [PUserEmail, setPUserEmail] = useState("");
  const [PUserMessage, setPUserMessage] = useState("");
  const [PEnquiryDate, setPEnquiryDate] = useState("");

  const AddPackageEnquiry = async () => {
    try {
      if (!PUserName) {
        return alert("Please add name");
      }
      if (!PUserEmail) {
        return alert("Please add email ");
      }
      if (!PUserPhone) {
        return alert("Please add phone number");
      }
      if (!PUserMessage) {
        return alert("Please add Your message");
      }

      // Generate the current date in the format 17-August-2024
      const currentDate = new Date().toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
      });

      const config = {
        url: "user/package",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
        data: {
          PUserName: PUserName,
          PUserPhone: PUserPhone,
          PUserEmail: PUserEmail,
          PUserMessage: PUserMessage,
          PEnquiryDate: currentDate,
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getPackage();
        setPUserName(" ");
        setPUserPhone(" ");
        setPUserEmail(" ");
        setPUserMessage(" ");
        handleClose();
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get  method
  const [Package, setPackage] = useState([]);
  const getPackage = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/user/getpackage");
      if (res.status === 200) {
        setPackage(res.data.getpackage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPackage();
  }, []);

  return (
    <div>
      <div>
        <Carousel fade>
          {AddSlider?.map((item, i) => {
            return (
              <Carousel.Item>
                <img
                  src={`https://nestwayinteriors.com/HomePage/${item?.SliderImage}`}
                  alt="First slide"
                  className="d-block w-100 slider-img"
                />
                <Carousel.Caption>
                  <div>
                    <div className="connect-popup-form">
                      <div className="slider-text-container">
                        <div className="text-display">
                          <div
                            className="banner-form-title mb-2"
                            data-aos="zoom-in"
                            data-aos-delay="50"
                            data-aos-duration="3000"
                          >
                            <h1>{item.SliderText}</h1>
                          </div>
                          <div className="">
                            <p
                              className="banner-form-desc mt-3"
                              data-aos="zoom-in"
                              data-aos-delay="50"
                              data-aos-duration="3000"
                            >
                              {item.SliderTagline}
                            </p>
                          </div>
                          <div>
                            <Button onClick={handleShow}>Enquiry Now</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            );
          })}
        </Carousel>

        <div
          className="fixed-icon"
          style={{ border: "none", backgroundColor: "unset !important" }}
        >
          <div>
            <a
              href="//"
              target="_new"
              style={{ color: "unset", textDecoration: "none" }}
            >
              {" "}
              <img
                src="../../Assets/WhatsApp_icon.png"
                alt=""
                style={{ width: "50px", height: "50px" }}
              />
              <span className="tooltip-text">Let's chat</span>
            </a>
          </div>
        </div>

        <div>
          <div
            className="scrool-up-icon"
            style={{ display: visible ? "inline" : "none" }}
          >
            <div className="scrool mb-2" onClick={scrollToTop}>
              <FaArrowCircleUp style={{ fontSize: "40px", color: "#106b87" }} />
            </div>

            <div className="scrool" onClick={scrollToBottom}>
              <FaArrowCircleDown
                style={{ fontSize: "40px", color: "#106b87" }}
              />
            </div>
          </div>
        </div>

        <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Package Enquiry
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <div>
                  <input
                    type="text"
                    placeholder="Enter Your Name *"
                    className="vi_1 mb-2"
                    required
                    value={PUserName}
                    onChange={handleNameChange}
                  />
                  {!isNameValid && (
                    <p style={{ color: "red" }}>
                      Please enter a valid name (only letters ).
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <div>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter Your Email *"
                    className="vi_1 mb-2"
                    required
                    onChange={(e) => setPUserEmail(e.target.value)}
                  />
                  {!isEmailValid && (
                    <p style={{ color: "red" }}>
                      Please enter a valid email address.
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <div>
                  <input
                    type="number"
                    id="number"
                    name="number"
                    placeholder="+91 Enter Contact Number *"
                    className="vi_1 mb-2"
                    required
                    onChange={(e) => setPUserPhone(e.target.value)}
                  />
                  {!isPhoneValid && (
                    <p style={{ color: "red" }}>
                      Please enter a valid 10-digit phone number.
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <textarea
                  name=""
                  id=""
                  className="vi_0"
                  placeholder="Message *"
                  style={{ height: "6rem" }}
                  onChange={(e) => setPUserMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={AddPackageEnquiry}
              >
                Submit
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default HomeSlider;
