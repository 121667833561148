import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import parse from "html-react-parser";

const AdminVisionMission = () => {
  // Add modal
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Delete modal
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // integrating post method
  const formdata = new FormData();
  const [VisionmissionImage, setVisionmissionImage] = useState("");
  const [VisionDescription, setVisionDescription] = useState("");
  const [MissionDescription, setMissionDescription] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setVisionDescription(data);
  };

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setMissionDescription(data);
  };

  const Addvisonmisiondata = async () => {
    formdata.append("VisionmissionImage", VisionmissionImage);
    formdata.append("VisionDescription", VisionDescription);
    formdata.append("MissionDescription", MissionDescription);

    try {
      if (!VisionmissionImage) {
        return alert("Please add Images");
      }
      if (!VisionDescription) {
        return alert("Please add Vision Description");
      }
      if (!MissionDescription) {
        return alert("Please add Mission Description");
      }
      const config = {
        url: "admin/visionmission",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getAddvisionmission();
        handleClose();
        setVisionmissionImage("");
        setVisionDescription("");
        setMissionDescription("");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get method
  const [Addvisionmission, setAddvisionmission] = useState([]);
  const getAddvisionmission = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getvisionmission"
      );
      if (res.status === 200) {
        setAddvisionmission(res.data.getvisionmission);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const DeleteVision = async () => {
    try {
      const config = {
        url: "admin/Deletevisionmission/" + Data,
        method: "delete",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getAddvisionmission();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
    setVisionDescription(item?.VisionDescription);
    setMissionDescription(item?.MissionDescription);
  };

  const EditVisionmision = async (e) => {
    e.preventDefault();
    formdata.append("VisionmissionImage", VisionmissionImage);
    formdata.append("VisionDescription", VisionDescription);
    formdata.append("MissionDescription", MissionDescription);
    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/editvisionmission",
        method: "put",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose3();
          getAddvisionmission();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getAddvisionmission();
  }, []);

  return (
    <div>
      <div>
        <div className="customerhead p-2">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="header-c ">Vision & Mission</h2>
            <div>
              {Addvisionmission?.length !== 0 ? (
                ""
              ) : (
                <>
                  <button className="admin-add-btn" onClick={handleShow}>
                    Add Vision & Mission
                  </button>
                </>
              )}
            </div>
          </div>

          <div className="mb-3">
            <Table
              responsive
              bordered
              style={{ width: "-webkit-fill-available" }}
            >
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Vision Description</th>
                  <th>Mission Description</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Addvisionmission?.map((item, i) => {
                  return (
                    <tr key={i}>
                      <td style={{ paddingTop: "20px" }}>
                        <Image
                          src={`https://nestwayinteriors.com/WebManagement/${item?.VisionmissionImage}`}
                          alt="pic"
                          style={{ width: "75px", height: "75px" }}
                        />
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        <div className="scroller">
                          {parse(`<div>${item.VisionDescription}</div>`)}
                        </div>
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        <div className="scroller">
                          {parse(`<div>${item.MissionDescription}</div>`)}
                        </div>
                      </td>
                      <td style={{ paddingTop: "20px" }}>
                        {" "}
                        <div
                          style={{
                            display: "flex",
                            gap: "20px",
                            justifyContent: "center",
                          }}
                        >
                          <div>
                            <BiSolidEdit
                              className="text-success"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => handleShow3(item)}
                            />{" "}
                          </div>
                          <div>
                            <AiFillDelete
                              className="text-danger"
                              style={{ cursor: "pointer", fontSize: "20px" }}
                              onClick={() => {
                                handleShow4();
                                setData(item?._id);
                              }}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>

          {/* Add About us modal */}
          <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black" }}>
                Add Vision & Mission
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setVisionmissionImage(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Vision Description</label>
                  <CKEditor editor={ClassicEditor} onChange={handleChange} />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Add Mission Description</label>
                  <CKEditor editor={ClassicEditor} onChange={handleChange1} />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="d-flex">
                <Button
                  className="mx-2 modal-close-btn"
                  variant=""
                  onClick={handleClose}
                >
                  Close
                </Button>
                <Button
                  className="mx-2 modal-add-btn"
                  variant=""
                  onClick={Addvisonmisiondata}
                >
                  Add
                </Button>
              </div>
            </Modal.Footer>
          </Modal>

          {/* Edit About us modal */}
          <Modal
            show={show3}
            onHide={handleClose3}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >
            <Modal.Header closeButton>
              <Modal.Title style={{ color: "black" }}>
                Edit Vision & Mission
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Image</label>
                  <input
                    type="file"
                    name=""
                    id=""
                    className="vi_0"
                    onChange={(e) => setVisionmissionImage(e.target.files[0])}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Vision Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={VisionDescription}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="do-sear mt-2">
                  <label>Edit Mission Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={MissionDescription}
                    onChange={handleChange1}
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="modal-close-btn"
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                variant=""
                className="modal-add-btn"
                onClick={EditVisionmision}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Delet modal  */}
          <Modal
            show={show4}
            onHide={handleClose4}
            backdrop="static"
            keyboard={false}
            style={{ zIndex: "99999" }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col-md-12">
                  <p className="fs-4" style={{ color: "red" }}>
                    Are you sure?
                    <br /> you want to delete this data?
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant=""
                className="modal-close-btn"
                onClick={handleClose4}
              >
                Close
              </Button>
              <Button
                variant=""
                className="modal-add-btn"
                onClick={DeleteVision}
              >
                Delete
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AdminVisionMission;
