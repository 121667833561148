import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";
import parse from "html-react-parser";

const AdminWhatweOffer = () => {
  // Add modal
  const [show, setShow] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // Delete modal
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // integrating post method
  const formdata = new FormData();
  const [WhatweofrImage, setWhatweofrImage] = useState("");
  const [WhatweofrHeading, setWhatweofrHeading] = useState("");
  const [WhatweofrText1, setWhatweofrText1] = useState("");
  const [WhatweofrText2, setWhatweofrText2] = useState("");
  const [WhatweofrText3, setWhatweofrText3] = useState("");
  const [WhatweofrText4, setWhatweofrText4] = useState("");
  const [WhatweofrDescription, setWhatweofrDescription] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setWhatweofrDescription(data);
  };

  const AddWhatweofrdata = async () => {
    formdata.append("WhatweofrImage", WhatweofrImage);
    formdata.append("WhatweofrHeading", WhatweofrHeading);
    formdata.append("WhatweofrText1", WhatweofrText1);
    formdata.append("WhatweofrText2", WhatweofrText2);
    formdata.append("WhatweofrText3", WhatweofrText3);
    formdata.append("WhatweofrText4", WhatweofrText4);
    formdata.append("WhatweofrDescription", WhatweofrDescription);

    try {
      if (!WhatweofrImage) {
        return alert("Please add Images");
      }
      if (!WhatweofrHeading) {
        return alert("Please add Heading");
      }
      if (!WhatweofrText1) {
        return alert("Please add Text 1");
      }
      if (!WhatweofrText2) {
        return alert("Please add Text 2");
      }
      if (!WhatweofrText3) {
        return alert("Please add Text 3");
      }
      if (!WhatweofrText4) {
        return alert("Please add Text 4");
      }
      if (!WhatweofrDescription) {
        return alert("Please add Description");
      }
      const config = {
        url: "admin/whatweofr",
        method: "post",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.success);
        getWhatweofr();
        handleClose();
        setWhatweofrImage("");
        setWhatweofrHeading("");
        setWhatweofrText1("");
        setWhatweofrText2("");
        setWhatweofrText3("");
        setWhatweofrText4("");
        setWhatweofrDescription("");
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //integrating get method
  const [Whatweofr, setWhatweofr] = useState([]);
  const getWhatweofr = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getwhatweofr");
      if (res.status === 200) {
        setWhatweofr(res.data.getwhatweofr);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //delete method
  const [Data, setData] = useState("");
  const Deletwhatweofr = async () => {
    try {
      const config = {
        url: "admin/Deletewhatweofr/" + Data,
        method: "delete",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "application/json" },
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("Successfully Delete");
          getWhatweofr();
          handleClose4();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.msg);
    }
  };

  //update method
  const [Data1, setData1] = useState("");
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const handleShow3 = (item) => {
    setShow3(true);
    setData1(item);
    setWhatweofrHeading(item?.WhatweofrHeading);
    setWhatweofrText1(item?.WhatweofrText1);
    setWhatweofrText2(item?.WhatweofrText2);
    setWhatweofrText3(item?.WhatweofrText3);
    setWhatweofrText4(item?.WhatweofrText4);
    setWhatweofrDescription(item?.WhatweofrDescription);
  };

  const Editwhtaweofr = async (e) => {
    e.preventDefault();
    formdata.append("WhatweofrImage", WhatweofrImage);
    formdata.append("WhatweofrHeading", WhatweofrHeading);
    formdata.append("WhatweofrText1", WhatweofrText1);
    formdata.append("WhatweofrText2", WhatweofrText2);
    formdata.append("WhatweofrText3", WhatweofrText3);
    formdata.append("WhatweofrText4", WhatweofrText4);
    formdata.append("WhatweofrDescription", WhatweofrDescription);
    formdata.append("id", Data1?._id);
    try {
      const config = {
        url: "admin/editwhatweofr",
        method: "put",
        baseURL: "https://nestwayinteriors.com/api/",
        header: { "content-type": "multipart/form-data" },
        data: formdata,
      };
      await axios(config).then((res) => {
        if (res.status === 200) {
          alert("successfully Update");
          handleClose3();
          getWhatweofr();
        }
      });
    } catch (error) {
      console.log(error);
      alert(error.response.data.error);
    }
  };

  useEffect(() => {
    getWhatweofr();
  }, []);

  return (
    <div>
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">What We Offer</h2>
          <div>
            {Whatweofr?.length !== 0 ? (
              ""
            ) : (
              <>
                <button className="admin-add-btn" onClick={handleShow}>
                  Add What We Offer
                </button>
              </>
            )}
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Image</th>
                <th>Heading</th>
                <th>Text1</th>
                <th>Text2</th>
                <th>Text3</th>
                <th>Text4</th>
                <th>Description</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {Whatweofr?.map((item, i) => {
                return (
                  <tr key={i}>
                    <td style={{ paddingTop: "20px" }}>
                      <Image
                        src={`https://nestwayinteriors.com/WebManagement/${item?.WhatweofrImage}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />
                    </td>
                    <td style={{ paddingTop: "20px" }}>
                      {item.WhatweofrHeading}
                    </td>
                    <td style={{ paddingTop: "20px" }}>
                      {item.WhatweofrText1}
                    </td>
                    <td style={{ paddingTop: "20px" }}>
                      {item.WhatweofrText2}
                    </td>
                    <td style={{ paddingTop: "20px" }}>
                      {item.WhatweofrText3}
                    </td>
                    <td style={{ paddingTop: "20px" }}>
                      {item.WhatweofrText4}
                    </td>
                    <td style={{ paddingTop: "20px" }}>
                      <div className="scroller">
                        {parse(`<div>${item.WhatweofrDescription}</div>`)}
                      </div>
                    </td>
                    <td style={{ paddingTop: "20px" }}>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className="text-success"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => handleShow3(item)}
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className="text-danger"
                            style={{ cursor: "pointer", fontSize: "20px" }}
                            onClick={() => {
                              handleShow4();
                              setData(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add About us modal */}
        <Modal show={show} onHide={handleClose} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Add What We Offer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setWhatweofrImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Heading</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Heading"
                  onChange={(e) => setWhatweofrHeading(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Text 1</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Text 1"
                  onChange={(e) => setWhatweofrText1(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Text 2</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Text 2"
                  onChange={(e) => setWhatweofrText2(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Text 3</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Text 3"
                  onChange={(e) => setWhatweofrText3(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Text 4</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Text 4"
                  onChange={(e) => setWhatweofrText4(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Description</label>
                <CKEditor editor={ClassicEditor} onChange={handleChange} />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={AddWhatweofrdata}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit About us modal */}
        <Modal
          show={show3}
          onHide={handleClose3}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit What We Offer
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setWhatweofrImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Heading</label>
                <input
                  type="text"
                  className="vi_0"
                  value={WhatweofrHeading}
                  onChange={(e) => setWhatweofrHeading(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Text 1</label>
                <input
                  type="text"
                  className="vi_0"
                  value={WhatweofrText1}
                  onChange={(e) => setWhatweofrText1(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Text 2</label>
                <input
                  type="text"
                  className="vi_0"
                  value={WhatweofrText2}
                  onChange={(e) => setWhatweofrText2(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Text 3</label>
                <input
                  type="text"
                  className="vi_0"
                  value={WhatweofrText3}
                  onChange={(e) => setWhatweofrText3(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Text 4</label>
                <input
                  type="text"
                  className="vi_0"
                  value={WhatweofrText4}
                  onChange={(e) => setWhatweofrText4(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  data={WhatweofrDescription}
                  onChange={handleChange}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose3}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={Editwhtaweofr}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/* Delet modal  */}
        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={Deletwhatweofr}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default AdminWhatweOffer;
