import React, { useState, useEffect } from "react";
import { Button, Row, Col, Modal, Form, Container } from "react-bootstrap";
import "../Styles/home.css";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import OwlCarousel from "react-owl-carousel";
import { FaRegLightbulb } from "react-icons/fa";
import { TbTruckDelivery } from "react-icons/tb";
import { FaLink } from "react-icons/fa";
import { FaRegCircleCheck } from "react-icons/fa6";
import Aos from "aos";
import Banner from "./HomeSlider";
import { FaArrowRight } from "react-icons/fa6";
import axios from "axios";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import { FaQuoteLeft } from "react-icons/fa";
import { FaQuoteRight } from "react-icons/fa";

const Home = () => {
  const navigate = useNavigate();

  // OUR SERVICES CARUSEL
  const options1 = {
    margin: 30,
    responsiveclassName: true,
    nav: true,
    dots: false,
    autoplay: true,
    smartSpeed: 5000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,
      },
    },
  };

  useEffect(() => {
    Aos.init();
  });

  // testimonial
  const responsive2 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1, // Corrected to 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1, // Corrected to 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // Corrected to 1
    },
  };
  // OUR PROJECT CARUSEL
  const options = {
    margin: 30,
    responsiveclassName: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 500,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 1,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  // Scrool window icon
  // const [visible, setVisible] = useState(false);

  // const toggleVisible = () => {
  //   const scrolled = document.documentElement.scrollTop;
  //   if (scrolled > 300) {
  //     setVisible(true);
  //   } else if (scrolled <= 300) {
  //     setVisible(false);
  //   }
  // };

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //     /* you can also use 'auto' behaviour
  //           in place of 'smooth' */
  //   });
  // };

  // window.addEventListener("scroll", toggleVisible);

  //======================ABOUT US============================//
  //integrating get method
  const [Aboutus, setAboutus] = useState([]);
  const getAboutus = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getaboutus");
      if (res.status === 200) {
        setAboutus(res.data.getaboutus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAboutus();
  }, []);

  //======================HOW WE WORK============================//
  //integrating get method
  const [Howwework, setHowwework] = useState([]);
  const getHowwework = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/gethowwework");
      if (res.status === 200) {
        setHowwework(res.data.gethowwework);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getHowwework();
  }, []);

  //integrating get method of work process
  const [AddWorkprocess, setAddWorkprocess] = useState([]);
  const getAddWorkprocess = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getworkprocess"
      );
      if (res.status === 200) {
        setAddWorkprocess(res.data.getworkprocess);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddWorkprocess();
  }, []);

  //======================OUR SERVICES============================//
  //integrating get method
  const [Addservice, setAddservice] = useState([]);
  const getAddservice = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getservice");
      if (res.status === 200) {
        setAddservice(res.data.getservice);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddservice();
  }, []);

  //======================OUR PROJECTS============================//
  //integrating get method
  const [Ourproject, setOurproject] = useState([]);
  const getOurproject = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getourproject"
      );
      if (res.status === 200) {
        setOurproject(res.data.getourproject);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOurproject();
  }, []);

  //integrating get method of gallery image
  const [Addgalleryimg, setAddgalleryimg] = useState([]);
  const getAddgalleryimg = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getgalleryimg"
      );
      if (res.status === 200) {
        setAddgalleryimg(res.data.getgalleryimg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddgalleryimg();
  }, []);

  //======================WHY CHOOSE US============================//
  //integrating get method
  const [Addwhyus, setAddwhyus] = useState([]);
  const getAddwhyus = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getwhychooseus"
      );
      if (res.status === 200) {
        setAddwhyus(res.data.getwhychooseus);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddwhyus();
  }, []);

  //integrating get method of our work
  const [Ourwork, setOurwork] = useState([]);
  const getOurwork = async () => {
    try {
      let res = await axios.get("https://nestwayinteriors.com/api/admin/getourwork");
      if (res.status === 200) {
        setOurwork(res.data.getourwork);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOurwork();
  }, []);

  //======================QUALITY OF WORK============================//
  //integrating get method
  
  const [Qualitywork, setQualitywork] = useState([]);
  const getQualitywork = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getqualitywork"
      );
      if (res.status === 200) {
        setQualitywork(res.data.getqualitywork);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQualitywork();
  }, []);

  //integrating get method of Quality Counts
  const [Qualitycount, setQualitycount] = useState([]);
  console.log("Qualitycount", Qualitycount);
  const getQualitycount = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/getqualitycounts"
      );
      if (res.status === 200) {
        setQualitycount(res.data.getqualitycounts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getQualitycount();
  }, []);

  // Read More
  const [showModal, setShowModal] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");

  const handleReadMore = (description) => {
    setCurrentDescription(description);
    setShowModal(true);
  };

  const truncateText = (text) => {
    const maxLength = 300; // Approximate character limit for three lines
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  // Testimonial
  //integrating get  method
  const [AddTestimonial, setAddTestimonial] = useState([]);
  const getAddTestimonial = async () => {
    try {
      let res = await axios.get(
        "https://nestwayinteriors.com/api/admin/gettestimonial"
      );
      if (res.status === 200) {
        setAddTestimonial(res.data.gettestimonial);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAddTestimonial();
  }, []);

  // how we work process modal
  const [show1, setShow1] = useState();
  const handleClose1 = () => setShow1(false);
  const [selectedDescription, setSelectedDescription] = useState("");

  // Function to handle showing the modal with the selected description
  const handleShow1 = (description) => {
    setSelectedDescription(description);
    setShow1(true);
  };

  return (
    <div>
      {/* Home Slider  */}
      <Banner />

      {/* ABOUT US  */}
      <Container fluid>
        {Aboutus?.map((item, i) => {
          const isLongDescription = item.AboutusDescription.length > 300;
          const shortDescription = item.AboutusDescription.slice(0, 300);
          return (
            <div className="row mt-5 mb-2" key={i}>
              <div
                className="col-md-7 mb-2"
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="3000"
              >
                <div>
                  <div className="about_us1">
                    <img
                      src={`https://nestwayinteriors.com/WebManagement/${item?.AboutusImage}`}
                      alt="abc"
                      className="about-img"
                    />
                  </div>
                </div>
              </div>
              <div
                className="col-md-5  mb-2"
                data-aos="fade-in"
                data-aos-delay="50"
                data-aos-duration="3000"
              >
                <div
                  className="about-content"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                  data-aos-duration="3000"
                >
                  <div className="about_us-title">
                    <h6>ABOUT US</h6>
                  </div>

                  <div className="about_us-title mt-3">
                    <h3>{item.AboutusHeading}</h3>
                  </div>
                  <div>
                    <p className="about_us-title mt-3">
                      {parse(
                        `<div>${
                          isLongDescription
                            ? shortDescription + "..."
                            : item.AboutusDescription
                        }</div>`
                      )}
                    </p>
                    {/* {isLongDescription && (
                      <a
                        href={`/aboutus/${item._id}`}
                        className="read-more-link"
                      >
                        Read More
                      </a>
                    )} */}
                  </div>

                  <div style={{ position: "relative", bottom: "0px" }}>
                    <a href="/aboutus">
                      <Button className="about-more-btn">
                        View More <HiOutlineArrowLongRight />{" "}
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Container>

      {/* HOW WE WORK  */}

      <Container fluid>
        <div className="row mt-5 mb-2">
          <div className="about_us-title">
            <h6>HOW WE WORK</h6>
          </div>
          <Row
            className="mt-2"
            data-aos="zoom-in"
            data-aos-delay="50"
            data-aos-duration="3000"
          >
            <Col md={3} className="mb-2">
              {Howwework?.map((item, i) => (
                <div key={i}>
                  <div className="how-we-wrk-title">
                    <h1>{item.HowweworkHeading}</h1>
                  </div>
                  <div
                    className="how-we-wrk-content mt-3"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="3000"
                  >
                    <p>{parse(`<div>${item.HowweworkDescription}</div>`)}</p>
                  </div>
                </div>
              ))}
            </Col>
            <Col md={9} className="mb-2">
              <Row>
                {AddWorkprocess?.map((item, i) => {
                  // Check if the description is longer than 200 characters
                  const isLongDescription =
                    item.WorkprocessDescription.length > 130;
                  const shortDescription = item.WorkprocessDescription.slice(
                    0,
                    130
                  );

                  return (
                    <Col md={4} key={i} className="mb-2">
                      <div className="how-we-wrk-box">
                        <div className="how-we-wrk-number">
                          <h1>{item.WorkprocessNumber}</h1>
                        </div>
                        <div className="how-we-wrk-small-title">
                          <h6>{item.WorkprocessHeading}</h6>
                        </div>
                        <div className="how-we-wrk-desc">
                          <p>
                            {parse(
                              `<div>${
                                isLongDescription
                                  ? shortDescription + "..."
                                  : item.WorkprocessDescription
                              }</div>`
                            )}
                          </p>
                          {isLongDescription && (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleShow1(item.WorkprocessDescription);
                              }}
                              className="read-more"
                            >
                              Read More
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
        <Modal
          show={show1}
          onHide={handleClose1}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>How We Work</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                {parse(`<div>${selectedDescription}</div>`)}
              </div>{" "}
              {/* Show selected description */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose1}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>

      {/* OUR SERVICES  */}
      <Container fluid>
        <div
          className="row mt-3 mb-3"
          data-aos="zoom-in"
          data-aos-delay="50"
          data-aos-duration="3000"
        >
          <div className="about_us-title mt-2 mb-3">
            <h6 style={{ textAlign: "center" }}>OUR SERVICES</h6>
          </div>
          <OwlCarousel className="owl-theme" loop margin={5} nav {...options1}>
            {Addservice?.map((item, i) => {
              return (
                <div className="item">
                  <div
                    className="card service-crd"
                    onClick={() =>
                      navigate("/service-overview", { state: item })
                    }
                  >
                    <div className="image-box">
                      <img
                        src={`https://nestwayinteriors.com/Services/${item?.ServiceImage}`}
                        alt=""
                        className="homepage-service-img"
                      />
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div className="service-names">{item.ServiceName}</div>
                        <div className="shake">
                          <FaArrowRight />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </OwlCarousel>
        </div>
      </Container>

      {/* PROJECTS  */}

      <Container fluid>
        {Addgalleryimg?.length === 0 ? (
          "" // Or you can display a message or loading indicator if needed
        ) : (
          <div className="our-projects-main-section">
            <Container
              data-aos="zoom-in"
              data-aos-delay="50"
              data-aos-duration="3000"
            >
              <div>
                {Ourproject?.map((item, i) => {
                  return (
                    <div className="justify-content-center" key={i}>
                      <div className="about_us-title mt-2 mb-3">
                        <h6 style={{ textAlign: "center" }}>OUR PROJECTS</h6>
                      </div>
                      <div className="our-projects-subtitle">
                        <h2>{item.OurprojectHeading}</h2>
                      </div>
                      <div className="our-projects-desc">
                        <p>{item.OurprojectTagline}</p>
                      </div>
                    </div>
                  );
                })}
              </div>

              <OwlCarousel
                className="owl-theme"
                loop
                margin={5}
                nav
                {...options}
              >
                {Addgalleryimg?.map((item, i) => {
                  return (
                    <div className="item" key={i}>
                      <a
                        href={`/gallery?category=${encodeURIComponent(
                          item.GalleryCategory
                        )}`}
                      >
                        <div className="card our-projects-crd">
                          <div className="image-box">
                            <img
                              src={`https://nestwayinteriors.com/Gallery/${item?.GalleryImage}`}
                              alt=""
                              className="homepage-our-projects-img"
                            />
                          </div>
                          <div className="card-body">
                            <h5 className="our-projects-crd-title">
                              {item.GalleryCategory}
                            </h5>
                          </div>
                        </div>
                      </a>
                    </div>
                  );
                })}
              </OwlCarousel>
            </Container>
          </div>
        )}
      </Container>

      {/* WHY CHOOSE US  */}
      <Container fluid>
        <div className="row mt-2 mb-3 justify-content-center">
          <div className="col-md-12">
            <div className="about_us-title mt-3 mb-2 text-center">
              <h6>WHY CHOOSE US</h6>
            </div>
            {Addwhyus?.map((item, i) => {
              return (
                <div className="why-coose-us-subtitle mt-2 mb-3 text-center">
                  <h2>{item.WhyusHeading}</h2>
                  <span className="mt-3 text-center p-5">{item.WhyusTagline}</span>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="row mt-2 mb-3"
          data-aos="zoom-in"
          data-aos-delay="50"
          data-aos-duration="3000"
        >
          {Ourwork?.map((item, i) => {
            return (
              <div className="col-md-3">
                <div className="why-cus-us-crd">
                  <div className="why-cus-us-crd-icon">
                    <img
                      src={`https://nestwayinteriors.com/HomePage/${item?.OurworkImage}`}
                          alt="pic"
                      style={{
                        width: "80px",
                        height: "80px",
                        background: "#bd9d65",
                        padding: "10px",
                        borderRadius: "25px",
                      }}
                    />
                  </div>
                  <div className="why-cus-us-crd-title mt-4">
                    <h6>{item.OurworkHeading}</h6>
                  </div>
                  <div className="p-2">
                  {/* className="why-cus-us-crd-desc" */}
                    <p >{parse(`<div>${item.OurworkDescription}</div>`)}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Container>

      {/* QUALITY OF WORK */}
      <div className="quality-container1 mt-5">
        <div className="overlay1">
          <div className="row">
            <div
              className="col-md-5"
              data-aos="zoom-in"
              data-aos-delay="50"
              data-aos-duration="3000"
            >
              <div>
                <div className="quality-of-wrk-title">
                  <h5 style={{ color: "#bd9d65" }}>QUALITY OF WORK</h5>
                </div>
                {Qualitywork?.map((item, i) => {
                  return (
                    <div className="quality-of-wrk-subtitle mt-3 mb-2">
                      <h3>{item.QualityworkHeading}</h3>
                      <div className="mt-3">{item.QualityworkTagline}</div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div
              className="col-md-7 position-relative"
              data-aos="zoom-in"
              data-aos-delay="50"
              data-aos-duration="3000"
            >
              {/* Vertical Line */}
              <div className="liness"></div>
              {/* Horizontal Line */}
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "0",
                  width: "100%",
                  height: "2px",
                  backgroundColor: "white",
                  transform: "translateY(-50%)",
                  zIndex: 1,
                }}
              ></div>

              <div className="row" style={{ position: "relative", zIndex: 2 }}>
                {Qualitycount.slice(0, 2).map((item, index) => (
                  <div key={index} className="col-md-6">
                    <div>
                      <div className="numbers">
                        <div className="quality-container-number">
                          <h2>{item?.QualitycountsNumber}+</h2>
                        </div>
                        <div className="quality-container-text">
                          <span>{item?.QualitycountsName}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="row" style={{ position: "relative", zIndex: 2 }}>
                {Qualitycount.slice(2, 4).map((item, index) => (
                  <div key={index} className="col-md-6">
                    <div>
                      <div className="numbers">
                        <div className="quality-container-number">
                          <h2>{item?.QualitycountsNumber}+</h2>
                        </div>
                        <div className="quality-container-text">
                          <span>{item?.QualitycountsName}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="web">
                <div
                  className="row "
                  data-aos="zoom-in"
                  data-aos-delay="50"
                  data-aos-duration="3000"
                >
                  <div className="col-md-6">
                    <div style={{ borderRight: "1px solid white" }}>
                      <div className="numbers">
                        <div className="quality-container-number">
                          <h2>22+</h2>
                        </div>
                        <div className="quality-container-text">
                          <span>Interior Design</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="numbers">
                      <div className="quality-container-number">
                        <h2>82+</h2>
                      </div>
                      <div className="quality-container-text">
                        <span>Architechture</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ borderBottom: "1px solid white" }}></div>
                <div
                  className="row"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                  data-aos-duration="3000"
                >
                  <div className="col-md-6">
                    <div style={{ borderRight: "1px solid white" }}>
                      <div className="numbers">
                        <div className="quality-container-number">
                          <h2>42+</h2>
                        </div>
                        <div className="quality-container-text">
                          <span>Construction</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="numbers">
                      <div className="quality-container-number">
                        <h2>58+</h2>
                      </div>
                      <div className="quality-container-text">
                        <span>Projects Done</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* TESTIMONIALA  */}
      <div className="mt-5 mb-5">
        <div className="testimonial-bg">
          <div className="testimonial-container1 mt-1 mb-4">
            <div className="overlay4">
              <div>
                <h2
                  className="headdings pt-1 mb-3 text-center"
                  style={{ color: "#bd9d65", fontSize: "30px" }}
                >
                  Testimonials{" "}
                </h2>
              </div>
              <Container
                data-aos="fade-up"
                data-aos-delay="50"
                data-aos-duration="3000"
              >
                <Carousel
                  responsive={responsive2}
                  margin={0}
                  autoPlay={true}
                  infinite={true}
                  autoPlaySpeed={6000}
                  transitionDuration={500}
                  arrows={false}
                  showDots={false}
                >
                  {AddTestimonial?.map((val, i) => {
                    return (
                      <div className="item " key={i}>
                        <div className="card testimonial-crd">
                          <div className="row justify-content-center p-0">
                            <div>
                              <div className="d-flex res-testimonial mt-5">
                                <FaQuoteLeft />
                                &nbsp;
                                <div className="mt-1 text-truncate-three-lines">
                                  {parse(truncateText(val.Description))}
                                  {val.Description.length > 300 && (
                                    <span
                                      className="read-more cursor-pointer"
                                      onClick={() =>
                                        handleReadMore(val.Description)
                                      }
                                    >
                                      Read More
                                    </span>
                                  )}
                                </div>
                                &nbsp;
                                <FaQuoteRight />
                              </div>
                            </div>

                            <div className="names-location mb-3">
                              <div>{val.Name}</div>
                              <div>{val.Designation}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </Container>
            </div>
          </div>
        </div>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Testimonial</Modal.Title>
          </Modal.Header>
          <Modal.Body>{parse(currentDescription)}</Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Testimonial</Modal.Title>
        </Modal.Header>
        <Modal.Body>{parse(currentDescription)}</Modal.Body>
        <Modal.Footer>
          <Button
            variant=""
            className="modal-add-btn"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Home;
